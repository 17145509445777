import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import SwapHoriz from "@mui/icons-material/SwapHoriz";
import { Box, IconButton, FormControl, OutlinedInput, Stack, Tooltip, Typography, ToggleButton, ToggleButtonGroup, MenuItem, Select } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { NumericFormatCustom } from "../../shared/fields/NumberFieldFormat";
import AccountDropdown from "../../shared/fields/AccountDropdown";
import BalancePreview from "../../shared/fields/BalancePreview";
import PairSelector from "../dashboard/orderEntry/PairSelector";
import { ignoreScrollEvent } from "../../util";
import { getPairPrice } from "../../apiServices";

function ChainedOrderFormItem({
  accounts,
  balances,
  dispatch,
  favouritePairs,
  index,
  orderItemState,
  setFavouritePairs,
  showAlert,
  tokenPairs,
  maxPriority,
}) {
  const theme = useTheme();
  const [selectedAcc, setSelectedAcc] = useState(orderItemState.accounts);
  const [selectedPairPrice, setSelectedPairPrice] = useState({ pair: '', price: 0 });
  const [fetchPairAttempts, setFetchPairAttempts] = useState(0);
  const [limitPrice, setLimitPrice] = useState(orderItemState.limit_price || '');

  const fetchPairPrice = async () => {
    if (fetchPairAttempts > 2 || !orderItemState.pair) {
      return null;
    }

    let pairPrice = selectedPairPrice.price;
    const selectedPairName = orderItemState.pair.id;
    if (selectedPairPrice.pair !== selectedPairName) {
      try {
        const result = await getPairPrice(selectedPairName, accounts[selectedAcc[0].name].exchangeName);
        pairPrice = result[selectedPairName];
      } catch (e) {
        setFetchPairAttempts(fetchPairAttempts + 1);
        return null;
      }
      setFetchPairAttempts(0);
      setSelectedPairPrice({ pair: selectedPairName, price: pairPrice });
    }

    return pairPrice;
  };

  useEffect(() => {
    if (orderItemState.pair) {
      fetchPairPrice();
    }
  }, [orderItemState.pair]);


  const calculateAssetBalance = (symbol) => {
    let totalAmount = 0;
    selectedAcc.forEach((accountIteration) => {
      if (!balances[accounts[accountIteration.name].id]) return;
      balances[accounts[accountIteration.name].id].assets.forEach((asset) => {
        if (asset.symbol === symbol) {
          totalAmount += asset.amount;
        }
      });
    });
    return totalAmount;
  };

  const calculateQuoteAssetBalance = (pairPrice) => {
    if (!orderItemState.pair) {
      return null;
    }
    return !orderItemState.pair.is_inverse
      ? calculateAssetBalance(orderItemState.pair.quote)
      : calculateAssetBalance(orderItemState.pair.base) * pairPrice;
  };

  const totalBaseBalance = () => {
    if (!orderItemState.pair) return null;
    const baseAsset = orderItemState.pair.is_contract ? orderItemState.pair.id : orderItemState.pair.base;
    return Math.abs(calculateAssetBalance(baseAsset));
  };

  const handleAccountChange = (event) => {
    const { value } = event.target;
    const selectedAccounts = value.map((account_name) => accounts[account_name]);
    setSelectedAcc(selectedAccounts);

    dispatch({
      type: 'UPDATE_ROW',
      payload: {
        rowIndex: index,
        accounts: selectedAccounts,
        pair: orderItemState.pair,
        side: orderItemState.side,
        qty: orderItemState.qty,
        limit_price: orderItemState.limit_price,
        priority: orderItemState.priority,
      },
    });
  };

  const handlePairChange = (value) => {
    if (!value) {
      dispatch({
        type: 'UPDATE_ROW',
        payload: {
          rowIndex: index,
          accounts: orderItemState.accounts,
          pair: null,
          side: orderItemState.side,
          qty: orderItemState.qty,
          limit_price: orderItemState.limit_price,
          priority: orderItemState.priority,
        },
      });
      return;
    }

    dispatch({
      type: 'UPDATE_ROW',
      payload: {
        rowIndex: index,
        accounts: orderItemState.accounts,
        pair: value,
        side: orderItemState.side,
        qty: orderItemState.qty,
        limit_price: orderItemState.limit_price,
        priority: orderItemState.priority,
      },
    });
  };

  const handleQtyChange = (event) => {
    dispatch({
      type: 'UPDATE_ROW',
      payload: {
        rowIndex: index,
        accounts: orderItemState.accounts,
        pair: orderItemState.pair,
        side: orderItemState.side,
        qty: event.target.value,
        limit_price: orderItemState.limit_price,
        priority: orderItemState.priority,
      },
    });
  };

  const handleLimitPriceChange = (event) => {
    setLimitPrice(event.target.value);
    dispatch({
      type: 'UPDATE_ROW',
      payload: {
        rowIndex: index,
        accounts: orderItemState.accounts,
        pair: orderItemState.pair,
        side: orderItemState.side,
        qty: orderItemState.qty,
        limit_price: event.target.value,
        priority: orderItemState.priority,
      },
    });
  };

  const handleSideChange = (event, newSide) => {
    if (newSide !== null) {
      dispatch({
        type: 'UPDATE_ROW',
        payload: {
          rowIndex: index,
          accounts: orderItemState.accounts,
          pair: orderItemState.pair,
          side: newSide,
          qty: orderItemState.qty,
          limit_price: orderItemState.limit_price,
          priority: orderItemState.priority,
        },
      });
    }
  };

  const handlePriorityChange = (event) => {
    const newPriority = parseInt(event.target.value, 10);
    dispatch({
      type: 'CHANGE_PRIORITY',
      payload: {
        rowIndex: index,
        newPriority,
      },
    });
  };

  const handleDeleteOnClick = () => {
    dispatch({
      type: 'REMOVE_ROW',
      payload: { rowIndex: index },
    });
  };

  const swapAssetOnClick = () => {
    dispatch({
      type: 'UPDATE_ROW',
      payload: {
        rowIndex: index,
        accounts: orderItemState.accounts,
        pair: orderItemState.pair,
        side: orderItemState.side,
        qty: '',
        isBaseAsset: !orderItemState.isBaseAsset,
      }
    });
  };

  const isPairSelected = orderItemState.pair && orderItemState.pair.id !== '';
  const asset = orderItemState.isBaseAsset ? orderItemState.pair.base : orderItemState.pair.quote;
  const isReadyToPickQty = orderItemState.accounts && orderItemState.accounts.length > 0 && isPairSelected;

  const swapAssetTooltipMessage = orderItemState.isBaseAsset
    ? `Swap asset to ${orderItemState.pair?.quote}`
    : `Swap asset to ${orderItemState.pair?.base}`;

  return (
    <Stack alignItems='start' direction='row' sx={{ paddingBottom: 1, paddingTop: 0, width: '100%' }}>
      {/* Priority Selector */}
      <FormControl sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '50px', marginRight: '0.5%' }} variant="outlined">
        <Select
          displayEmpty
          inputProps={{ 'aria-label': 'Priority' }}
          value={orderItemState.priority}
          onChange={handlePriorityChange}
        >
          {[...Array(maxPriority + 1).keys()].slice(1).map((num) => (
            <MenuItem key={num} value={num}>
              {num}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Account Dropdown */}
      <Box position='relative' sx={{ width: '250px', marginRight: '0.5%' }}>
        <AccountDropdown
          multiple
          accounts={accounts}
          extraStyling={{ height: '50.25px' }}
          handleSelectedAccountsChange={handleAccountChange}
          selectedAccounts={selectedAcc.map((acc) => acc.name)}
        />
      </Box>

      {/* Pair Selector */}
      <Box
        alignItems='center'
        display="flex"
        height='40.25px'
        justifyContent='center'
        padding='4px'
        sx={{
          cursor: 'pointer',
          border: `1px solid ${theme.palette.text.disabled}`,
          borderRadius: '4px',
          width: '250px',
          marginRight: '0.5%',
        }}
      >
        <PairSelector
          ChainedOrders
          accounts={accounts}
          balances={balances}
          favourites={favouritePairs}
          pairs={tokenPairs}
          selectedAccounts={selectedAcc.map((acc) => acc.name)}
          selectedPairName={orderItemState.pair?.id}
          setFavourites={setFavouritePairs}
          setSelectedPair={handlePairChange}
          showAlert={showAlert}
        />
      </Box>

      {/* Quantity Input and Balance Preview */}
      <FormControl sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '220px', marginRight: '0.5%' }} variant="outlined">
        <Tooltip
          disableFocusListener={isPairSelected}
          disableHoverListener={isPairSelected}
          title='Please select a trading pair'
        >
          <OutlinedInput
            fullWidth
            disabled={!isPairSelected}
            endAdornment={
              <>
                <Typography sx={{ display: isPairSelected ? 'inline' : 'none' }} variant="body2">
                  {asset}
                </Typography>
                <Tooltip title={swapAssetTooltipMessage}>
                  <span>
                    <IconButton disabled={!isPairSelected} onClick={swapAssetOnClick}>
                      <SwapHoriz />
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            }
            inputComponent={NumericFormatCustom}
            inputProps={{ step: 'any' }}
            placeholder='Quantity'
            style={{ paddingRight: 0 }}
            sx={{
              '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
              },
              'input[type=number]': {
                MozAppearance: 'textfield',
              },
            }}
            value={orderItemState.qty}
            onChange={handleQtyChange}
            onWheel={ignoreScrollEvent}
          />
        </Tooltip>
        <BalancePreview
          balances={balances}
          isBase={orderItemState.isBaseAsset}
          isReadyToPickQty={isReadyToPickQty}
          selectedPair={isPairSelected ? orderItemState.pair : null}
          totalBaseAsset={totalBaseBalance}
          totalQuoteAsset={() => {
            if (!selectedPairPrice.price) {
              fetchPairPrice();
            }
            return calculateQuoteAssetBalance(selectedPairPrice.price);
          }}
        />
      </FormControl>

      {/* Limit Price Input */}
      <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '180px', marginRight: '0.5%' }} variant="outlined">
        <OutlinedInput
          fullWidth
          disabled={!isPairSelected}
          inputComponent={NumericFormatCustom}
          inputProps={{ step: 'any' }}
          placeholder='Limit Price'
          sx={{
            '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
            'input[type=number]': {
              MozAppearance: 'textfield',
            },
          }}
          value={limitPrice}
          onChange={handleLimitPriceChange}
          onWheel={ignoreScrollEvent}
        />
      </FormControl>

      {/* buy/sell Toggle */}
      <Box alignItems="center" display="flex" sx={{ width: '120px', marginRight: '0.5%' }}>
        <ToggleButtonGroup
          exclusive
          aria-label="side"
          sx={{ height: '40.25px' }}
          value={orderItemState.side}
          onChange={handleSideChange}
        >
          <ToggleButton aria-label="buy" value="buy">
            Buy
          </ToggleButton>
          <ToggleButton aria-label="sell" value="sell">
            Sell
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {/* Remove Order Item */}
      <Tooltip title='Remove order item'>
        <IconButton sx={{ height: '75%' }} onClick={handleDeleteOnClick}>
          <RemoveCircleOutline sx={{ fontSize: 25, color: theme.palette.error.main }} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}

export default ChainedOrderFormItem;