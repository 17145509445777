import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import ICONS from '../../../images/exchange_icons';
import getBaseTokenIcon from '../../../images/tokens';
import { getTokenPairLookup, getUserFavouritePairs } from '../../apiServices';
import { formatPrice } from '../../util';
import PairSelector from './orderEntry/PairSelector';
import { usePriceDataContext } from './orderEntry/PriceDataContext';
import { useMarketDataContext } from './orderEntry/MarketDataContext';
import { useUserMetadata } from '../../shared/context/UserMetadataProvider';

export function PairInfoBar({
  exchangeName,
  FormAtoms,
  selectedPairName,
  showAlert,
}) {
  const { livePairPrice } = usePriceDataContext();
  const { marketSummaryMetrics, noData, reloading } = useMarketDataContext();
  const [favouritePairs, setFavouritePairs] = useAtom(
    FormAtoms.favouritePairsAtom
  );
  const [initialLoadValue] = useAtom(FormAtoms.initialLoadValueAtom);
  const [selectedAccounts] = useAtom(FormAtoms.selectedAccountsAtom);
  const [selectedPair, setSelectedPair] = useAtom(FormAtoms.selectedPairAtom);
  const [balances, setBalances] = useAtom(FormAtoms.balancesAtom);
  const [tokenPairLookup, setTokenPairLookup] = useAtom(
    FormAtoms.tokenPairLookUpAtom
  );
  const { accounts, tokenPairs } = initialLoadValue;

  const { user } = useUserMetadata();

  const loadFavouritePairs = async () => {
    let pairs;

    try {
      const result = await getUserFavouritePairs();
      pairs = result.pairs;
    } catch (e) {
      showAlert({
        severity: 'error',
        message: `Unable to load favourite pairs: ${e.message}`,
      });
      return;
    }

    setFavouritePairs(
      pairs.reduce((acc, pair) => {
        return { ...acc, [pair]: true };
      }, {})
    );
  };

  const loadTokenPairLookup = async () => {
    let data;

    try {
      const result = await getTokenPairLookup();
      data = result.pairs;
    } catch (e) {
      showAlert({
        severity: 'error',
        message: `Unable to load token pair lookup: ${e.message}`,
      });
      return;
    }

    setTokenPairLookup(data);
  };

  useEffect(() => {
    if (user && user.is_authenticated) {
      loadFavouritePairs();
      loadTokenPairLookup();
    }
  }, [user]);

  const shortenNumber = (value) => {
    if (value >= 10 ** 6) {
      return value / 10 ** 6;
    }
    if (value >= 10 ** 3) {
      return value / 10 ** 3;
    }

    return value;
  };
  const getUnit = (value) => {
    if (value >= 10 ** 6) {
      return 'M';
    }
    if (value >= 10 ** 3) {
      return 'K';
    }

    return '';
  };

  const buildMarketSummaryLines = () => {
    const priceDiff = marketSummaryMetrics.priceDiff
      ? marketSummaryMetrics.priceDiff.toFixed(2)
      : null;
    const volume = marketSummaryMetrics.past24Volume
      ? shortenNumber(marketSummaryMetrics.past24Volume).toFixed(2)
      : null;
    const evr = marketSummaryMetrics.evr
      ? marketSummaryMetrics.evr.toFixed(2)
      : null;
    const priceVolatility = marketSummaryMetrics.priceVolatility
      ? shortenNumber(marketSummaryMetrics.priceVolatility).toFixed(2)
      : null;
    const pv = marketSummaryMetrics.predictedVolume
      ? shortenNumber(marketSummaryMetrics.predictedVolume).toFixed(2)
      : null;

    return [
      {
        value: {
          number: priceDiff,
          unit: '%',
        },
        label: '24H Price',
      },
      {
        value: {
          number: volume,
          unit: getUnit(marketSummaryMetrics.past24Volume),
        },
        label: '24H Volume',
        tooltip: 'Total reported volume in the last 24H',
      },
      {
        value: {
          number: evr,
          unit: 'x',
        },
        label: '24H E.V.R.',
        tooltip:
          'Expected Volume Ratio: ( realized volume / expected volume ) in the past 24H',
      },
      {
        value: {
          number: priceVolatility,
          unit: '%',
        },
        label: '1H Volatility',
        tooltip: 'Expected price volatility for the next hour',
      },
      {
        value: {
          number: pv,
          unit: getUnit(marketSummaryMetrics.predictedVolume),
        },
        label: '1H P.V.',
        tooltip: 'Total predicted volume for the next hour',
      },
    ];
  };

  const evrColor = (value) => {
    if (value < 0.5) {
      return 'error.main';
    }
    if (value < 0.75) {
      return 'warning.main';
    }
    if (value <= 1) {
      return 'info.main';
    }
    return 'success.light';
  };

  const marketSummaryValueColor = (line) => {
    if (line.value.number === null) {
      return 'grey.main';
    }

    if (line.label.includes('E.V.R')) {
      return evrColor(line.value.number);
    }

    if (
      line.label.includes('Volume') ||
      line.label.includes('Volatility') ||
      line.label.includes('P.V.')
    ) {
      return 'info.main';
    }

    if (line.value.number >= 0) {
      return 'success.light';
    }

    return 'error.main';
  };

  const livePriceColor = () => {
    if (livePairPrice === '') {
      return 'grey.main';
    }

    if (marketSummaryMetrics.priceDiff === undefined) {
      return 'text.primary';
    }

    return marketSummaryMetrics.priceDiff >= 0 ? 'success.light' : 'error.main';
  };

  const renderMarketSummaryValue = (line) => {
    let value = line.value.number;

    if (line.value.number === null) {
      return 'N/A';
    }

    if (line.label.includes('Volatility')) {
      value = `\u00B1${line.value.number}`;
    } else if (line.label.includes('Price')) {
      value = `${value > 0 ? '+' : ''}${value}`;
    }

    return `${value}${line.value.unit}`;
  };

  const pairDisplayIcon = selectedPair
    ? getBaseTokenIcon(selectedPair.base)
    : null;

  return (
    <Box display='flex' flexDirection='row' gap={2} height='100%' width='100%'>
      <Box alignItems='center' display='flex' gap={1}>
        {pairDisplayIcon && (
          <Box
            alignItems='center'
            display='flex'
            paddingX='8px'
            position='relative'
          >
            <img
              alt='Token Icon'
              src={pairDisplayIcon}
              style={{ height: '30px', width: '30px' }}
            />
            <Box
              alignItems='center'
              bottom={0}
              display='flex'
              position='absolute'
              right={0}
            >
              <img
                alt={exchangeName}
                src={ICONS[exchangeName.toLowerCase()]}
                style={{ height: '15px', width: '15px', borderRadius: '50%' }}
              />
            </Box>
          </Box>
        )}
        <div style={{ cursor: 'pointer' }}>
          <PairSelector
            accounts={accounts}
            balances={balances}
            favourites={favouritePairs}
            pairs={tokenPairs}
            selectedAccounts={selectedAccounts}
            selectedPairName={selectedPairName}
            setFavourites={setFavouritePairs}
            setSelectedPair={setSelectedPair}
            showAlert={showAlert}
          />
        </div>
      </Box>
      <Stack
        alignItems='center'
        direction='row'
        flexGrow={1}
        gap={2}
        justifyContent='start'
      >
        <Box alignSelf='center' paddingX='10px'>
          <Typography
            color={livePriceColor()}
            fontFamily={['monospace', 'IBM Plex Mono']}
            textAlign='left'
            variant='h3'
          >
            {livePairPrice ? formatPrice(Number(livePairPrice)) : ''}
          </Typography>
        </Box>
        {!noData &&
          !reloading &&
          Object.keys(marketSummaryMetrics).length > 0 &&
          buildMarketSummaryLines().map((line) => (
            <Tooltip key={line.label} title={line.tooltip}>
              <Box display='flex' flexDirection='column' flexGrow='0'>
                <Typography
                  color='grey.main'
                  textAlign='left'
                  variant='caption'
                >
                  {line.label}
                </Typography>
                <Typography
                  color={() => marketSummaryValueColor(line)}
                  fontFamily={['monospace', 'IBM Plex Mono']}
                  sx={{
                    paddingRight: '15px',
                  }}
                  textAlign='left'
                  variant='body1'
                >
                  {renderMarketSummaryValue(line)}
                </Typography>
              </Box>
            </Tooltip>
          ))}
      </Stack>
    </Box>
  );
}
