import {
  Card,
  CardContent,
  CardHeader,
  Icon,
  Link,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { useState, useEffect, useContext } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useNavigate } from 'react-router-dom';
import {
  displayDefaultTableCell,
  StyledHeaderTableCellWithLine,
  StyledPaddingTableCell,
  StyledTableCell,
} from '../../shared/orderTable/util';
import { getArweaveData } from '../../apiServices';
import { ErrorContext } from '../../shared/context/ErrorProvider';
import arweaveLight from '../../../images/logos/arweave-light.png';
import ICONS from '../../../images/exchange_icons';
import ExplorerTradesTable from './ExplorerTradesTable';
import ExplorerProofsTable from './ExplorerProofsTable';

const columns = [
  { id: 'id', label: 'Transaction ID', width: 260, align: 'left' },
  { id: 'exchange_name', label: 'Exchange', width: 50, align: 'left' },
  { id: 'trader_id', label: 'User', width: 60, align: 'left' },
  { id: 'epoch', label: 'Epoch', width: 30, align: 'left' },
  { id: 'merkle_root', label: 'Hash', width: 80, align: 'left' },
  { id: 'usd_volume', label: 'Volume', width: 30, align: 'right' },
];

function insertEllipsis(str, startSlice = 4, endSlice = 3) {
  if (!str) {
    return '';
  }

  if (str.length <= 7) {
    return str; // No need to shorten
  }

  const start = str.slice(0, startSlice);
  const end = str.slice(-1 * endSlice);

  return `${start}...${end}`;
}

export default function ExplorerPage() {
  const { setHasError, setErrorContent } = useContext(ErrorContext);

  return (
    <Box
      spacing={2}
      sx={{
        height: '900px',
        width: '80%',
        margin: '0 auto',
      }}
    >
      <div
        style={{ height: '60px', paddingTop: '10px', paddingBottom: '10px' }}
      >
        <Box>
          <Typography
            fontFamily='Jost'
            fontSize={28}
            fontWeight={400}
            variant='h2'
          >
            On-Chain Explorer
          </Typography>
        </Box>
      </div>
      <Stack direction='row' spacing={2}>
        <Box
          sx={{
            textAlign: 'left',
            width: '45%',
            height: '825px',
          }}
        >
          <ExplorerTradesTable
            pageView
            setErrorContent={setErrorContent}
            setHasError={setHasError}
          />
        </Box>
        <Box
          sx={{
            textAlign: 'right',
            width: '45%',
            height: '825px',
          }}
        >
          <ExplorerProofsTable
            pageView
            setErrorContent={setErrorContent}
            setHasError={setHasError}
          />
        </Box>
      </Stack>
    </Box>
  );
}
