import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { titleCase, accurateToFixed } from '../../util';

export const StyledTableCell = styled(TableCell)(({ theme }) => {
  return {
    [`&.${StyledTableCell}-head`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      border: 'none',
    },
    [`&.${StyledTableCell}-body`]: {
      border: 'none',
    },
    borderBottom: '1px solid rgb(81 81 81)',
    fontFamily: ['monospace', 'IBM Plex Mono'],
    fontSize: theme.typography.body1.fontSize,
  };
});

export const StyledSummaryTableCell = styled(TableCell)(({ theme }) => {
  return {
    [`&.${StyledSummaryTableCell}-head`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      border: 'none',
    },
    [`&.${StyledSummaryTableCell}-body`]: {
      border: 'none',
    },
    padding: '4px',
    borderBottom: '1px solid rgb(81 81 81)',
    fontFamily: ['monospace', 'IBM Plex Mono'],
    fontSize: theme.typography.body1.fontSize,
  };
});

export const StyledBenchmarkTableCell = styled(TableCell)(({ theme }) => {
  return {
    [`&.${StyledSummaryTableCell}-head`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      border: 'none',
    },
    [`&.${StyledSummaryTableCell}-body`]: {
      border: 'none',
    },
    padding: '8px',
    borderBottom: '1px solid rgb(81 81 81)',
    fontFamily: ['monospace', 'IBM Plex Mono'],
    fontSize: theme.typography.body1.fontSize,
  };
});

export const StyledPaddingTableCell = styled(TableCell)(({ theme }) => {
  return {
    [`&.${StyledTableCell}-head`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      border: 'none',
    },
    [`&.${StyledTableCell}-body`]: {
      border: 'none',
    },
    borderBottom: '1px solid rgb(81 81 81)',
    fontFamily: ['monospace', 'IBM Plex Mono'],
    fontSize: theme.typography.body1.fontSize,
    padding: '1px 16px 1px 16px',
  };
});

export const StyledHeaderTableCellWithLine = styled(TableCell)(({ theme }) => {
  return {
    [`&.${StyledTableCell}-head`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      border: 'none',
    },
    [`&.${StyledTableCell}-body`]: {
      border: 'none',
    },
    borderBottom: '1px solid rgb(81 81 81)',
    fontSize: theme.typography.body1.fontSize,
  };
});

export const CustomColorStyledTableCell = (color) =>
  styled(TableCell)(({ theme }) => {
    return {
      [`&.${StyledTableCell}-head`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        border: 'none',
      },
      [`&.${StyledTableCell}-body`]: {
        border: 'none',
      },
      borderBottom: '1px solid rgb(81 81 81)',
      borderLeft: `1px solid ${color}`,
      fontFamily: ['monospace', 'IBM Plex Mono'],
      fontSize: '0.90rem',
    };
  });

export const ConditonalStyledTableCell = styled(TableCell)(({ theme }) => {
  return {
    [`&.${StyledTableCell}-head`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      border: 'none',
    },
    [`&.${StyledTableCell}-body`]: {
      border: 'none',
    },
    borderBottom: '1px solid rgb(81 81 81)',
    borderLeft: '1px solid #ffa344',
    fontSize: '0.95rem',
  };
});

export const StyledBorderTableCell = styled(TableCell)(({ theme }) => {
  return {
    [`&.${StyledBorderTableCell}-head`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    fontFamily: ['monospace', 'IBM Plex Mono'],
    fontSize: theme.typography.body1.fontSize,
    borderBottom: 'none',
    paddingTop: 0,
  };
});

export const StyledHeaderTableCell = styled(TableCell)(({ theme }) => {
  return {
    [`&.${StyledBorderTableCell}-head`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    fontSize: theme.typography.body1.fontSize,
    borderBottom: 'none',
    paddingBottom: 0,
  };
});

export const StyledBorderTopTableCell = styled(TableCell)(({ theme }) => {
  return {
    [`&.${StyledBorderTableCell}-head`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    borderTop: '1px solid rgb(49 50 51)',
    fontSize: '0.95rem',
    borderBottom: 'none',
  };
});

export const StyledIBMTypography = styled(Typography)(({ theme }) => {
  return {
    fontFamily: ['monospace', 'IBM Plex Mono'],
    fontSize: '0.80rem',
  };
});

// Options table

export const StyledStrikeTableCell = styled(TableCell)(({ theme }) => {
  return {
    [`&.${StyledTableCell}-head`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      border: 'none',
    },
    [`&.${StyledTableCell}-body`]: {
      border: 'none',
    },
    borderBottom: 'none',
    borderTop: 'none',
    fontFamily: ['monospace', 'IBM Plex Mono'],
    fontSize: '0.80rem',
  };
});

export const StyledTableOptionCell = styled(TableCell)(({ theme }) => {
  return {
    [`&.${StyledTableCell}-head`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      border: 'none',
    },
    [`&.${StyledTableCell}-body`]: {
      border: 'none',
    },
    borderBottom: '1px solid rgb(81 81 81)',
    borderRight: '1px solid rgb(81 81 81)',
    borderLeft: '1px solid rgb(81 81 81)',
    fontFamily: ['monospace', 'IBM Plex Mono'],
    fontSize: '0.80rem',
  };
});

export function StrategyParamsParsed({
  alpha_tilt,
  engine_passiveness,
  schedule_discretion,
  exposure_tolerance,
  strategy_params,
  pov_limit,
  pov_target,
  initial_pov_target,
  useIBM = false,
}) {
  const strategyParamsKeys = Object.keys(strategy_params);

  const StyledTypography = useIBM
    ? StyledIBMTypography
    : styled(Typography)(({ theme }) => {
        return {};
      });

  const parseWhiteSpace = useIBM ? <>&nbsp;</> : <>&nbsp;&nbsp;</>;
  // {useIBM ? " " : "  "}

  return (
    <>
      {parseFloat(alpha_tilt) !== 0 && (
        <StyledTypography>
          <li key='alpha_tilt'>
            Alpha Tilt: {useIBM ? <>&nbsp;</> : <>&nbsp;&nbsp;&nbsp;</>}
            {parseFloat(alpha_tilt).toFixed(2)}
          </li>
        </StyledTypography>
      )}
      <StyledTypography>
        <li key='engine_passiveness'>
          Passiveness: {parseFloat(engine_passiveness).toFixed(2)}
        </li>
      </StyledTypography>
      <StyledTypography>
        <li key='schedule_discretion'>
          Discretion: {parseWhiteSpace}
          {parseFloat(schedule_discretion).toFixed(2)}
        </li>
      </StyledTypography>
      {exposure_tolerance && (
        <StyledTypography>
          <li key='exposure_tolerance'>
            Exposure Tolerance:
            {parseWhiteSpace}
            {parseFloat(exposure_tolerance).toFixed(2)}
          </li>
        </StyledTypography>
      )}
      {pov_limit && pov_limit > 0 ? (
        <StyledTypography>
          <li key='pov_limit'>
            POV Limit: &nbsp;&nbsp;{accurateToFixed(parseFloat(pov_limit), 2)}%
          </li>
        </StyledTypography>
      ) : null}
      {pov_target && pov_target > 0 ? (
        <StyledTypography>
          <li key='pov_target'>
            POV Target: &nbsp;{initial_pov_target}
            {accurateToFixed(parseFloat(pov_target), 2)}%
          </li>
        </StyledTypography>
      ) : null}
      {strategyParamsKeys.length !== 0 &&
      Object.values(strategy_params).includes(true)
        ? strategyParamsKeys
            .filter((x) => strategy_params[x])
            .map((key) => {
              return (
                <StyledTypography key={key}>
                  <li>{titleCase(key)}</li>
                </StyledTypography>
              );
            })
        : null}
    </>
  );
}

export function buySellToBaseQuote(row) {
  const rowData = { ...row };

  if (rowData.side === 'buy') {
    if (rowData.buy_token_amount) {
      rowData.buy_token_amount -= rowData.executed_buy_qty;
    } else {
      rowData.sell_token_amount -= rowData.executed_qty;
    }
  } else if (rowData.buy_token_amount) {
    rowData.buy_token_amount -= rowData.executed_buy_qty;
  } else {
    rowData.sell_token_amount -= rowData.executed_qty;
  }

  return rowData;
}

export const displayDefaultTableCell = (
  column,
  value,
  style,
  CustomCell = StyledTableCell
) => {
  return (
    <CustomCell
      align={column.align}
      key={column.id}
      style={style}
      width={column.width}
    >
      {column.format && typeof value === 'number'
        ? column.format(value)
        : value}
    </CustomCell>
  );
};
export function formatDateTime(timeString) {
  const date = new Date(timeString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return (
    <span>
      <span style={{ color: 'white' }}>{`${year}-${month}-${day} `}</span>
      <span
        style={{ color: 'rgba(255, 255, 255, 0.7)' }}
      >{`${hours}:${minutes}:${seconds}`}</span>
    </span>
  );
}

export const getOrderPath = (order) => {
  let url = null;
  if (order.child_order_ids !== null && order.child_order_ids !== undefined) {
    url = `/multi_order/${order.id}`;
  } else if (order.is_simple) {
    url = `/simple_order/${order.id}`;
  } else if (order.side === 'Chained') {
    url = `/chained_orders/${order.id}`;
  } else {
    url = `/order/${order.id}`;
  }

  return url;
};
