import moment from 'moment';

import { useContext, useEffect, useState } from 'react';
import { ErrorContext } from 'shared/context/ErrorProvider';
import { ApiError, getPointsData } from 'apiServices';

function pointsDataCacheKey(dateRange, activityPage) {
  return `${dateRange.key}#${activityPage}`;
}

function useGetPointsData(dateRange, activityPage) {
  const { showAlert } = useContext(ErrorContext);
  const [pointsData, setPointsData] = useState({});
  const [pointsDataCache, setPointsDataCache] = useState({});
  const [pointsDataLoading, setPointsDataLoading] = useState(true);

  useEffect(() => {
    const cacheKey = pointsDataCacheKey(dateRange, activityPage);

    const fetchData = async () => {
      try {
        const endTime = moment.utc();
        const startTime = moment.utc().subtract(dateRange, 'days');
        const result = await getPointsData({
          startTime: startTime.toISOString(),
          endTime: endTime.toISOString(),
          activityPage: activityPage + 1,
        });
        setPointsDataCache((prev) => ({ ...prev, [cacheKey]: result }));
        setPointsData(result);
      } catch (e) {
        if (e instanceof ApiError) {
          showAlert({
            severity: 'error',
            message: `Failed to fetch points data: ${e.message}`,
          });
        }
      } finally {
        setPointsDataLoading(false);
      }
    };

    if (pointsDataCache[cacheKey]) {
      setPointsData(pointsDataCache[cacheKey]);
    } else {
      fetchData();
    }
  }, [dateRange, activityPage]);

  return { pointsData, pointsDataLoading };
}

export default useGetPointsData;
