import { Box, Button, Tooltip } from '@mui/material';
import React from 'react';

export function AmendButton({ status, setAmendDialogOpen, parentOrder }) {

  const amendDisabledTooltipMessage = () => {
    if (status === 'COMPLETE' || status === 'CANCELED') {
      return 'Order has terminated.';
    }

    if (parentOrder) {
      return 'Cannot amend child orders.';
    }

    return '';
  };

  return <Tooltip title={amendDisabledTooltipMessage()}>
    <Box width='25%'>
      <Button
        fullWidth
        aria-label="amend"
        color="primary"
        disabled={["COMPLETE", "CANCELED"].includes(status) || parentOrder}
        size="small"
        variant="contained"
        onClick={(event) => {
          event.stopPropagation();
          setAmendDialogOpen(true);
        }}
      >
        Amend
      </Button>
    </Box>
  </Tooltip>
}

const pauseDisabledTooltipMessage = (isPauseDisabled, status, super_strategy_name, parent_order) => {
  if (!isPauseDisabled) {
    return '';
  }

  if (status === 'COMPLETE' || status === 'CANCELED') {
    return 'Order has terminated.';
  }

  if (super_strategy_name === "Target Time") {
    return 'Cannot pause orders with Target Time strategy.';
  }

  if (parent_order) {
    return 'Please pause from parent order page.';
  }

  return '';
};

export function PauseResumeButton({
  status,
  isPaused,
  parent_order,
  super_strategy_name,
  setHandleConfirm,
  setConfirmModalText,
  setConfirmModalOpen,
  handlePause,
  handleResume
}) {
  const isPauseDisabled = (
    status === 'COMPLETE' ||
    status === 'CANCELED' ||
    super_strategy_name === "Target Time" ||
    parent_order
  );

  return (
    <Tooltip title={pauseDisabledTooltipMessage(isPauseDisabled, status, super_strategy_name, parent_order)}>
      <Box sx={{ width: '25%' }}>
        <Button
          fullWidth
          aria-label="pause"
          color="secondary"
          disabled={isPauseDisabled}
          size="small"
          variant="contained"
          onClick={(event) => {
            event.stopPropagation();
            setHandleConfirm(() => (isPaused ? handleResume : handlePause));
            setConfirmModalText(`Are you sure you want to ${isPaused ? "resume" : "pause"} this order?`);
            setConfirmModalOpen(true);
          }}
        >
          {isPaused ? 'Resume' : 'Pause'}
        </Button>
      </Box>
    </Tooltip>
  );
}

export function CancelButton({ status, setHandleConfirm, setConfirmModalText, setConfirmModalOpen, handleCancel }) {
  return <Button
    aria-label="cancel"
    color="error"
    disabled={["COMPLETE", "CANCELED"].includes(status)}
    size="small"
    sx={{ width: '25%' }}
    variant="contained"
    onClick={(event) => {
      event.stopPropagation();
      setConfirmModalText('Are you sure you want to cancel this order?');
      setHandleConfirm(() => handleCancel);
      setConfirmModalOpen(true);
    }}
  >
    Cancel
  </Button>
}
