/* eslint-disable no-shadow */
import React, { useState, useEffect} from 'react';
import { Button, Box, Typography, TableContainer, TableCell, TableHead,
  TableRow, TextField, Modal, TableBody, MenuItem, Tooltip, IconButton,
  FormControl, Select, Table, InputLabel, FormControlLabel, Checkbox, LinearProgress } from '@mui/material';
import { Delete, ArrowUpward, ArrowDownward } from '@mui/icons-material';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { Doughnut } from 'react-chartjs-2';
import { useTheme } from '@emotion/react';
import CachedIcon from '@mui/icons-material/Cached';
import ScaleLoader from 'react-spinners/ScaleLoader';
import {
  openInNewTab,
  startRebalance,
  stopRebalance,
  checkRebalance,
  checkAccountRebalance,
  getPairPrice,
  cancelMultiOrder,
  fetchOrderEntryFormData,
  getUserFavouritePairs,
  fetchMultiOrderDetailData,
} from '../../apiServices';
import PairSelector from '../dashboard/orderEntry/PairSelector';
import { msAndKs, truncate, BASEURL } from '../../util';
import { formatDateTime } from '../../shared/orderTable/util';

const RebalanceAssetsColumns = [
  { id: 'symbol', label: 'Name', minWidth: 70, align: 'left' },
  { id: 'targetWeight', label: 'Target Weight(%)', minWidth: 60, align: 'left' },
  { id: 'currentWeight', label: 'Current Weight(%)', minWidth: 60, align: 'right', number: true },
  { id: 'targetNotional', label: 'Target Notional', minWidth: 70, align: 'right', number: true },
  { id: 'currentNotional', label: 'Current Notional', minWidth: 70, align: 'right', number: true },
  { id: 'targetQuantity', label: 'Target Quantity', minWidth: 70, align: 'right', number: true },
  { id: 'currentQuantity', label: 'Current Quantity', minWidth: 70, align: 'right', number: true },
  { id: 'proposedTradeNotional', label: 'Proposed Trade Notional', minWidth: 70, align: 'right', number: true },
  { id: 'proposedTradeQuantity', label: 'Proposed Trade Quantity', minWidth: 70, align: 'right', number: true },
  { id: 'diff', label: 'Difference', minWidth: 70, align: 'right', number: true },
];

const filteredAssets = (assets) => {
  return assets.filter(asset => 
    asset.symbol && 
    typeof asset.symbol === 'string' &&
    !['USDT', 'USD', 'USDC'].includes(asset.symbol)
  );
};

function AccountRebalance({
  assetOrdering,
  balanceData,
  currentAssets,
  selectedAccount,
  selectedAccountFull,
  showAlert,  
}) {
  const [assetFilter, setAssetFilter] = useState("spot"); 
  const [targetWeights, setTargetWeights] = useState({});
  const [tolerance, setTolerance] = useState(1);
  const [rebalanceFrequency, setRebalanceFrequency] = useState("Hours");
  const [ourAssets, setOurAssets] = useState([]);
  const [rebalanceFrequencyValue, setRebalanceFrequencyValue] = useState(1);
  const [nextRebalance, setNextRebalance] = useState('');
  const [message, setMessage] = useState('');
  const [taskId, setTaskId] = useState([]);
  const [netBalance, setNetBalance] = useState({ assets: [] });
  const [counterAsset, setCounterAsset] = useState('USDT');
  const [currentPrices, setCurrentPrices] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [stopLoading, setStopLoading] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [status, setStatus] = useState(null);
  const [BalanceNotional, setBalanceNotional] = useState([]); 
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [rebalanceData, setRebalanceData] = useState(null); 
  const [timeStart, setTimeStart] = useState([]);
  const [childOrders, setChildOrders] = useState([]);
  const [isFloating, setIsFloating] = useState(false);
  const [pricesFetchedDuringRebalance, setPricesFetchedDuringRebalance] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tokenPairs, setTokenPairs] = useState([]);
  const [favouritePairs, setFavouritePairs] = useState([]);
  const [rebalanceProgress, setRebalanceProgress] = useState(0);
  const [positionTypes, setPositionTypes] = useState({});
  const [showCashRow, setShowCashRow] = useState(true);
  const [showStopConfirmation, setShowStopConfirmation] = useState(false);
  const theme = useTheme();

  const handleFilterChange = (event) => {
    const newFilter = event.target.value;
    setTargetWeights({});
    setTolerance(1);
    setIsFloating(false);
    setRebalanceFrequencyValue(1);
    setRebalanceFrequency("Hours");
    setCounterAsset('USDT');
    setPositionTypes({});
    setOurAssets([]);
    setAssetFilter(newFilter);
  };

  const filterAssetsByType = (assets) => {
    switch (assetFilter) {
    case "spot":
      return assets.filter(asset => !asset.symbol.includes(":") && !asset.symbol.includes("-"));
    case "margin":
      return assets.filter(asset => !asset.symbol.includes(":") && asset.symbol.includes("-"));
    case "perp":
      return assets.filter(asset => asset.symbol.includes(":PERP"));
    default:
      return assets;
    }
  };

  const filteredAssetsByType = filterAssetsByType(netBalance.assets);

  const fetchTokenPairs = async () => {
    try {
      setLoading(true);
      const response = await fetchOrderEntryFormData();
  
      const pairs = response.pairs
        .filter(pair => {
          if (pair.quote !== counterAsset) return false;
  
          switch (assetFilter) {
          case "spot":
            return !pair.is_contract;
          case "margin":
            return !pair.is_contract && pair.market_type === "margin";
          case "perp":
            return pair.is_contract && pair.market_type === "perp";
          default:
            return true;
          }
        })
        .map(pair => ({
          base: pair.base,
          exchanges: pair.exchanges,
          id: pair.name,
          is_contract: pair.is_contract,
          is_inverse: pair.is_inverse,
          label: pair.name,
          market_type: pair.market_type,
          quote: pair.quote,
        }));
  
      setTokenPairs(pairs);
    } catch (error) {
      showAlert({ severity: 'error', message: 'Failed to fetch token pairs.' });
    } finally {
      setLoading(false);
    }
  };
  
  const fetchFavouritePairs = async () => {
    try {
      setLoading(true);
      const response = await getUserFavouritePairs();
      const { pairs } = response;
  
      const favouritePairs = pairs
        .filter(pair => {
          const [base, quote] = pair.split(/[-]/);
          if (quote !== counterAsset) return false;
          switch (assetFilter) {
          case "spot":
            return pair.includes("-") && !pair.includes(":");
          case "margin":
            return !pair.includes("-") && !pair.includes(":");
          case "perp":
            return pair.includes(":PERP");
          default:
            return false;
          }
        })
        .reduce((acc, pair) => ({ ...acc, [pair]: true }), {});
  
      setFavouritePairs(favouritePairs);
    } catch (error) {
      showAlert({ severity: 'error', message: 'Failed to fetch favourite pairs.' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedAccount && selectedAccount[0] && selectedAccount[0] !== 'All Accounts') {
      fetchTokenPairs();
      fetchFavouritePairs();
    }
  }, [selectedAccount, counterAsset, assetFilter]);

  const filteredSelectedAssets = filteredAssets(filteredAssetsByType);
  const selectedAssets = filteredAssetsByType.map(asset => ({
    ...asset,
    symbol: asset.symbol.replace(/-USDT|-USDC|-USD$/, '')
  }));
  
  const combinedAllAssets = [...selectedAssets, ...ourAssets];
  const combinedAssets = [...filteredSelectedAssets, ...ourAssets];
  const inProgressAssets = [...combinedAssets];
  Object.keys(targetWeights).forEach(symbolOrPair => {
    const trimmedSymbolOrPair = symbolOrPair.replace(/-USDT|-USDC|-USD$/, '');
    const existingAsset = combinedAssets.find(asset => 
      (asset.symbol === trimmedSymbolOrPair || asset.pair === trimmedSymbolOrPair)
    );
  
    if (!existingAsset) {
      inProgressAssets.push({
        symbol: trimmedSymbolOrPair,
        currentQuantity: 0,
        currentNotional: 0,
      });
    }
  });

  const filteredInprogressAssets = inProgressAssets.filter((asset, index, self) => {
    const symbolOrPair = asset.symbol || asset.pair;
    const normalizedSymbol = symbolOrPair.replace(/-USDT|-USDC|-USD$/, '')
    const suffixedSymbol = `${normalizedSymbol}-USDT`;
    const hasTargetWeight = Object.keys(targetWeights).includes(normalizedSymbol) ||
     Object.keys(targetWeights).includes(suffixedSymbol);
    const isUnique = index === self.findIndex((a) => 
      (a.symbol || a.pair).replace(/-USDT|-USDC|-USD$/, '') === normalizedSymbol);

    if (hasTargetWeight && isUnique) { /* empty */ }

    return hasTargetWeight && isUnique;
  });

  const filteredCombinedAssets = filteredAssets(combinedAssets);
  const calculateCurrentWeight = (currentNotional, totalCurrentNotional) => {
    const weight = (currentNotional / totalCurrentNotional) * 100;
    return weight.toFixed(2);
  };  

  const trimSymbol = (symbol) => {
    return symbol.replace(/-USDT|-USDC|-USD$/, '');
  };

  const chartColors = [
    ...Object.values(theme.palette.exchangeBalance),
  ];

  const loadOrderData = async (order_id) => {
    try {
      const orderData = await fetchMultiOrderDetailData(order_id);  
      const pctFilled = orderData?.order?.pct_filled ?? 0;
      setRebalanceProgress(pctFilled);
    } catch (e) {
      showAlert({ severity: 'error', message: `Failed to fetch order details: ${e.message}` });
      setRebalanceProgress(0);
    }
  };  
  
  const fetchRebalanceStatus = async () => {
    const account_id = selectedAccount?.[0];
    if (!account_id || account_id === 'All Accounts') {
      return;
    }
  
    const assetsType = assetFilter;
  
    try {
      setLoading(true);
      const data = await checkRebalance(account_id, assetsType);
  
      if (data.is_rebalance_enabled === true) {
        setInProgress(true);
        setOrderId(data.parent_order_id);
        setTaskId(data.task_id);
        setStatus(data.status);
        setBalanceNotional(data.current_balance_notional);
        setTargetWeights(data.target_weights);
        setTolerance(data.tolerance)
        setNextRebalance(data.next_rebalance);
        setTimeStart(data.time_start);
        setIsFloating(data.is_floating_enabled);
  
        if (data.parent_order_id) {
          await loadOrderData(data.parent_order_id);
        }
      } else {
        setInProgress(false);
        setMessage('');
        setStatus(data.status);
      }
    } finally {
      setLoading(false);
    }
  };
  
  const fetchPairPrice = async (symbol) => {
    if (!symbol || ['USDT', 'USD', 'USDC'].includes(symbol)) {
      return null;
    }
  
    let exchangeName = selectedAccount?.[1] || ''; 
    if (exchangeName === 'All Accounts') {
      exchangeName = 'All Accounts';
    }
  
    const pair = symbol.includes(counterAsset) ? symbol : `${symbol}-${counterAsset}`;  
    try {
      const result = await getPairPrice(pair, exchangeName);
      const fetchedPrice = result[pair] || 0;
  
      if (fetchedPrice === 0) {
        showAlert({ severity: 'error', message: `Price not found for asset: ${symbol}` });
      }
  
      setCurrentPrices((prev) => {
        const updatedPrices = { ...prev, [symbol]: fetchedPrice };
        return updatedPrices;
      });
  
      return fetchedPrice;
    } catch (error) {
      showAlert({ severity: 'error', message: `Failed to fetch price for asset: ${symbol}` });
      return 0;
    }
  };
  
  const fetchPricesForAssets = async () => {
    const assetsToFetch = inProgress ? filteredInprogressAssets : netBalance.assets;
    
    await Promise.all(
      assetsToFetch.map((asset) => fetchPairPrice(asset.symbol))
    );
  
  };
  
  const initializeNetBalance = (selectedAccountBalances) => {
    const netBalance = { assets: [] };
  
  
    Object.keys(selectedAccountBalances).forEach((symbol) => {
      if (['USDT', 'USD', 'USDC'].includes(symbol)) {
        return;
      }
  
      const assetBalances = selectedAccountBalances[symbol];
      const lastBalance = assetBalances[assetBalances.length - 1] || {};
      const currentPrice = currentPrices[symbol] || 0;
  
      const currentNotional = lastBalance.notional || 0;
      const currentQuantity = currentPrice > 0 ? currentNotional / currentPrice : 0;
  
      netBalance.assets.push({
        symbol,
        currentQuantity,
        currentNotional,
      });
    });
  
    return netBalance;
  };  

  const handleToleranceChange = (e) => {
    setTolerance(parseFloat(e.target.value) || 0);
  };

  const handleInputChange = (symbol, event) => {
    const value = event.target.value === '' ? '' : parseFloat(event.target.value);
    const normalizedSymbol = symbol.replace(/-USDT|-USDC|-USD$/, '');
  
    setTargetWeights((prev) => {
      const updatedWeights = { ...prev };
      if (value === '' || Number.isNaN(value)) {
        delete updatedWeights[normalizedSymbol];
      } else {
        const positionType = positionTypes[normalizedSymbol] || 'long';
        updatedWeights[normalizedSymbol] = positionType === 'short' ? -Math.abs(value) : Math.abs(value);
      }
      return updatedWeights;
    });
  };
  

  const handleInputPairChange = (pair, event) => {
    const value = event.target.value === '' ? '' : parseFloat(event.target.value);
  
    setTargetWeights(prev => {
      const updatedWeights = { ...prev };
  
      if (value === '' || Number.isNaN(value)) {
        delete updatedWeights[pair];
      } else {
        updatedWeights[pair] = value;
      }
  
      return updatedWeights;
    });
  };

  useEffect(() => {
    if (selectedAccount && balanceData[selectedAccount[0]]) {
      const initialNetBalance = initializeNetBalance(balanceData[selectedAccount[0]]);
      setNetBalance(initialNetBalance);
      fetchPricesForAssets(initialNetBalance.assets);
    }
  }, [selectedAccount, balanceData]);

  useEffect(() => {
    if (selectedAccount && selectedAccount[0] !== 'All Accounts') {
      fetchRebalanceStatus();
  
      setTargetWeights({});
      setBalanceNotional([]);
      setTolerance(1);
      setIsFloating(false);
      setRebalanceFrequencyValue(1);
      setRebalanceFrequency("Hours");
      setCounterAsset('USDT');
      setAssetFilter("spot")
      setOurAssets([]);
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (inProgress && filteredInprogressAssets.length > 0 && !pricesFetchedDuringRebalance) {
      fetchPricesForAssets();
      setPricesFetchedDuringRebalance(true);
    }
  }, [inProgress, pricesFetchedDuringRebalance]);

  useEffect(() => {
    if (!inProgress) {
      setPricesFetchedDuringRebalance(false);
    }
  }, [inProgress]);


  const calculateProposedTrade = (targetWeight, currentNotional, currentPrice, totalNotional) => {
    if (targetWeight === undefined || targetWeight === null || targetWeight === ''
       || Number.isNaN(targetWeight) || currentPrice === 0) {
      return { proposedTradeNotional: 0, proposedTradeQuantity: 0, targetNotional: 0 };
    }
    if (targetWeight === 0) {
      return {
        targetNotional: 0,
        proposedTradeNotional: -currentNotional, 
        proposedTradeQuantity: currentPrice > 0 ? -currentNotional / currentPrice : 0,
      };
    }
    const targetNotional = (targetWeight / 100) * BalanceNotional;
    const proposedTradeNotional = targetNotional - currentNotional;
    const proposedTradeQuantity = currentPrice > 0 ? proposedTradeNotional / currentPrice : 0;
  
    return { proposedTradeNotional, proposedTradeQuantity, targetNotional };
  };  

  const calculateDiff = (targetWeight, currentWeight, targetNotional, currentNotional) => {
    if (targetWeight === 0) {
      return (-currentWeight).toFixed(2);
    }
    if (!targetWeight || targetWeight === '' || Number.isNaN(targetWeight)) {
      return '0.00';
    }
    if (!currentNotional || currentNotional === 0 || Number.isNaN(currentNotional)) {
      return targetWeight.toFixed(2);
    }
    const diff = (targetNotional / currentNotional - 1) * 100;
    return diff.toFixed(2) === '-0.00' ? '0.00' : diff.toFixed(2);
  };
  
  const handleRowCalculations = (asset) => {  
    const fullSymbol = asset.symbol;  
    const currentPrice = currentPrices[fullSymbol] || 0;  
    const currentNotional = asset.currentNotional || 0;  
    const totalCurrentNotional = combinedAssets.reduce((sum, asset) => sum + (asset.currentNotional || 0), 0);
    const currentWeight = calculateCurrentWeight(currentNotional, totalCurrentNotional);
    const normalizedSymbol = fullSymbol.replace(/-USDT|-USDC|-USD$/, '');
    const targetWeight = Object.prototype.hasOwnProperty.call(targetWeights, normalizedSymbol)
      ? parseFloat(targetWeights[normalizedSymbol])
      : undefined;    const { proposedTradeNotional, proposedTradeQuantity, targetNotional } = calculateProposedTrade(
      targetWeight,
      currentNotional,
      currentPrice,
      totalCurrentNotional
    );
  
    const diff = calculateDiff(targetWeight, currentWeight, targetNotional, currentNotional);
  
    return {
      currentPrice,
      currentWeight,
      targetNotional,
      currentNotional,
      proposedTradeNotional,
      proposedTradeQuantity,
      diff,
    };
  };  
  
  const filteredCombinedAllAssets = filteredAssets(combinedAllAssets)
  const assetsToUse = inProgress ? inProgressAssets : combinedAllAssets;

  let totalCurrentNotional;

  if (inProgress) {
    totalCurrentNotional = assetsToUse.reduce((sum, asset) => {
      const symbol = asset.symbol || (asset.pair && asset.pair.split('-')[0]);
      const hasTargetWeight = Object.prototype.hasOwnProperty.call(targetWeights, symbol) 
        && parseFloat(targetWeights[symbol]) !== 0;
  
      const currentNotional = assetFilter === "perp" ? Math.abs(asset.currentNotional || 0) : (asset.currentNotional || 0);
  
      return hasTargetWeight ? sum + currentNotional : sum;
    }, 0);
  } else {
    totalCurrentNotional = filteredCombinedAllAssets.reduce((sum, asset) => {
      const currentNotional = assetFilter === "perp" ? Math.abs(asset.currentNotional || 0) : (asset.currentNotional || 0);
      return sum + currentNotional;
    }, 0);
  }  
  
  const totalCurrentNotionalAll = combinedAllAssets.reduce((sum, asset) => {
    return sum + (asset.currentNotional || 0);
  }, 0);

  const totalTargetWeight = assetsToUse.reduce((sum, asset) => {
    const symbol = asset.symbol || (asset.pair && asset.pair.split('-')[0]);
    const targetWeight = Math.abs(parseFloat(targetWeights[symbol] || 0));
    return targetWeight ? sum + targetWeight : sum;
  }, 0).toFixed(2);

  let totalCurrentWeight;
  
  if (inProgress) {
    totalCurrentWeight = assetsToUse.reduce((sum, asset) => {
      const symbol = asset.symbol || (asset.pair && asset.pair.split('-')[0]);
      const hasTargetWeight = Object.prototype.hasOwnProperty.call(targetWeights, symbol) 
        && parseFloat(targetWeights[symbol]) !== 0;
  
      if (!hasTargetWeight) {
        return sum;
      }
  
      const currentNotional = asset.currentNotional || 0;
      const totalCurrentNotional = combinedAllAssets.reduce((totalSum, a) => {
        return totalSum + (a.currentNotional || 0);
      }, 0);
  
      const currentWeight = currentNotional > 0 && totalCurrentNotional > 0
        ? (currentNotional / totalCurrentNotional) * 100
        : 0;
  
      return sum + currentWeight;
    }, 0).toFixed(2);
  } else {
  
    totalCurrentWeight = combinedAllAssets.reduce((sum, asset) => {
      const currentNotional = asset.currentNotional || 0;
      const totalCurrentNotional = combinedAllAssets.reduce((totalSum, a) => {
        return totalSum + (a.currentNotional || 0);
      }, 0);
  
      const currentWeight = currentNotional > 0 && totalCurrentNotional > 0
        ? (currentNotional / totalCurrentNotional) * 100
        : 0;  
      return sum + currentWeight;
    }, 0).toFixed(2);
  }

  const totalProposedTradeNotional = assetsToUse.reduce((sum, asset) => {
    const baseSymbol = asset.pair ? asset.pair.split('-')[0] : asset.symbol;
    const fullSymbol = `${baseSymbol}-USDT`;
    const currentPrice = currentPrices[fullSymbol] || currentPrices[baseSymbol] || 0;
    const currentNotional = asset.currentNotional || 0;
    const normalizedSymbol = baseSymbol;
    const targetWeight = Object.prototype.hasOwnProperty.call(targetWeights, normalizedSymbol)
      ? parseFloat(targetWeights[normalizedSymbol])
      : NaN;
  
    if (Number.isNaN(targetWeight) || currentPrice === 0) {
      return sum;
    }
  
    let proposedTradeNotional = 0;
    if (targetWeight === 0) {
      proposedTradeNotional = -currentNotional;
    } else {
      const targetNotional = (targetWeight / 100) * BalanceNotional;
  
      proposedTradeNotional =
        assetFilter === "perp"
          ? Math.abs(targetNotional) - Math.abs(currentNotional)
          : targetNotional - currentNotional;
    }
  
    return sum + proposedTradeNotional;
  }, 0).toFixed(2);

  const totalTargetNotional = assetsToUse.reduce((sum, asset) => {
    const baseSymbol = asset.pair ? asset.pair.split('-')[0] : asset.symbol;
    const targetWeight = Object.prototype.hasOwnProperty.call(targetWeights, baseSymbol)
      ? parseFloat(targetWeights[baseSymbol])
      : NaN;
  
    if (Number.isNaN(targetWeight)) {
      return sum;
    }
  
    let targetNotional = targetWeight === 0 
      ? 0 
      : (targetWeight / 100) * BalanceNotional;
  
    if (assetFilter === "perp") {
      targetNotional = Math.abs(targetNotional);
    }
  
    return sum + targetNotional;
  }, 0).toFixed(2);

  const hasTargetWeights = Object.values(targetWeights).some((weight) => weight && parseFloat(weight) !== 0);

  let totalDiff = 0;

  if (hasTargetWeights) {
    if (totalCurrentNotional === 0) {
      totalDiff = parseFloat(totalTargetNotional) || 0;
    } else {
      totalDiff = ((parseFloat(totalTargetNotional) / totalCurrentNotional) - 1) * 100;
    }
  }
  
  const formattedTotalDiff = 
    typeof totalDiff !== 'number' || Number.isNaN(totalDiff) || totalDiff === null
      ? '0.00' 
      : totalDiff.toFixed(2); 

  const formattedTotalProposedTradeNotional = totalProposedTradeNotional === "-0.00" ? "0.00" 
    : totalProposedTradeNotional;
  const totalCurrentNotionalAllFormatted = msAndKs(truncate(totalCurrentNotionalAll), 2);  
  
  const handleBalanceNotionalChange = (e) => {
    const value = parseFloat(e.target.value) || 0; 
    setBalanceNotional(value); 
  };

  const getAssetQuantityForCounterAsset = () => {
    if (selectedAccount && balanceData[selectedAccount[0]]) {
      const selectedAccountBalances = balanceData[selectedAccount[0]];
      const assetBalances = selectedAccountBalances[counterAsset];
      if (assetBalances && assetBalances.length > 0) {
        const lastBalance = assetBalances[assetBalances.length - 1];
        if (lastBalance.amount) {
          return parseFloat(lastBalance.amount).toFixed(2);
        } if (lastBalance.notional) {
          return parseFloat(lastBalance.notional).toFixed(2);
        }
      }
    }
    return '0.00';
  };

  const handleRebalance = async () => {
    setSubmitLoading(true);
    setMessage('');
  
    if (!selectedAccount || !selectedAccount[0]) {
      showAlert({ severity: 'error', message: 'No account selected. Please select an account to rebalance.' });
      setSubmitLoading(false);
      return;
    }

    try {
      const rebalanceStatus = await checkAccountRebalance(selectedAccount[0]);
      if (rebalanceStatus.status === 'in_progress') {
        showAlert({
          severity: 'error',
          message: `Rebalance already in progress for account ${selectedAccount[0]}. Please wait for it to complete.`,
        });
        setSubmitLoading(false);
        return;
      }
    } catch (error) {
      showAlert({
        severity: 'error',
        message: `Failed to check rebalance status for account ${selectedAccount[0]}. Please try again.`,
      });
      setSubmitLoading(false);
      return;
    }
  
    const counterAssetBalance = parseFloat(getAssetQuantityForCounterAsset()) || 0;
    const combinedAssets = [...filteredAssets(netBalance.assets), ...ourAssets];
    const totalTargetWeight = Object.values(targetWeights).reduce((total, weight) => 
      total + parseFloat(weight || 0), 0);
  
    if (Math.abs(totalTargetWeight) <= 0 || Math.abs(totalTargetWeight) > 100) {
      showAlert({ severity: 'error', message: 
        'Total target weight must be greater than 0% and less than or equal to 100%.' });
      setSubmitLoading(false);
      return;
    }
  
    const invalidTargetWeights = Object.entries(targetWeights).filter(([symbol, weight]) => 
      parseFloat(weight) < -100 || parseFloat(weight) > 100);
    if (invalidTargetWeights.length > 0) {
      showAlert({ severity: 'error', message: 'Target weights must be between -100% and 100%.' });
      setSubmitLoading(false);
      return;
    }
  
    const checkTolerance = (currentWeight, targetWeight) => {
      const lowerBound = targetWeight * (1 - tolerance / 100);
      const upperBound = targetWeight * (1 + tolerance / 100);
      return lowerBound <= currentWeight && currentWeight <= upperBound;
    };
  
    const filteredAssetsWithTargetWeight = combinedAssets.filter((asset) => {
      const normalizedSymbol = (asset.symbol || asset.pair.split('-')[0]).replace(/-USDT$/, '');
      const currentNotional = asset.currentNotional || 0;
      const currentWeight = calculateCurrentWeight(currentNotional, BalanceNotional);
      const targetWeight = parseFloat(targetWeights[normalizedSymbol] || 0);
      const targetNotional = targetWeight * (BalanceNotional / 100);
      const diff = parseFloat(calculateDiff(targetWeight, currentWeight, targetNotional, currentNotional));

      if (Math.abs(diff) < tolerance) {
        return false;
      }
  
      return Object.prototype.hasOwnProperty.call(targetWeights, normalizedSymbol);
    });
  
    if (filteredAssetsWithTargetWeight.length === 0) {
      showAlert({ severity: 'info', message: 'All assets are within tolerance. No rebalancing needed.' });
      setSubmitLoading(false);
      return;
    }
  
  
    const child_orders = filteredAssetsWithTargetWeight.map((asset) => {
      const symbolOrPair = trimSymbol(asset.symbol || asset.pair);
      const pair = symbolOrPair.includes(counterAsset) ? symbolOrPair : `${symbolOrPair}-${counterAsset}`;
      const currentPrice = currentPrices[symbolOrPair] || currentPrices[pair] || 0;
      if (currentPrice === 0) {
        return {
          accounts: [selectedAccount[1]],
          pair,
          side: 'sell',
          base_asset_qty: 0,
        };
      }
      const targetWeight = parseFloat(targetWeights[symbolOrPair] || 0);
      const { proposedTradeNotional } = calculateProposedTrade(
        targetWeight,
        asset.currentNotional,
        currentPrice,
        totalCurrentNotional
      );
    
      let side;
      if (targetWeight < 0) {
        side = 'sell';
      } else if (proposedTradeNotional > 0) {
        side = 'buy';
      } else {
        side = 'sell';
      }

      if (assetFilter === "perp" || assetFilter === "margin") {
        const positionType = targetWeight < 0 ? 'short' : 'long';
        if (positionType === 'short') {
          side = 'sell';
        } else if (positionType === 'long') {
          side = 'buy';
        }}
      const base_asset_qty = currentPrice > 0 ? Math.abs(proposedTradeNotional / currentPrice) : 0;
      return {
        accounts: [selectedAccount[1]],
        pair,
        side,
        base_asset_qty,
      };
    });
  
    const counterAssetCalculation = parseFloat(getAssetQuantityForCounterAsset());
    const totalCurrentNotionalAndCounterAsset = totalCurrentNotional + counterAssetCalculation;
  
    if (BalanceNotional > totalCurrentNotionalAndCounterAsset) {
      showAlert({ severity: 'error', message: `Balance notional (${BalanceNotional.toFixed(2)} ${counterAsset}) 
      exceeds the available balance (${totalCurrentNotionalAndCounterAsset.toFixed(2)} ${counterAsset}).` });
      setSubmitLoading(false);
      return;
    }
  
    const maxAllowedBalanceNotional = BalanceNotional * 1.05;
    if (isFloating && maxAllowedBalanceNotional > totalCurrentNotionalAllFormatted) {
      showAlert({
        severity: 'error',
        message: `Floating balance notional (${maxAllowedBalanceNotional.toFixed(2)} ${counterAsset}) 
        exceeds the available balance (${totalCurrentNotionalAllFormatted.toFixed(2)} ${counterAsset}).`,
      });
      setSubmitLoading(false);
      return;
    }
  
    if (formattedTotalProposedTradeNotional > counterAssetBalance) {
      showAlert({ severity: 'error', message: 'Insufficient liquidity for rebalancing.' });
      setSubmitLoading(false);
      return;
    }
  
    const duration = 900;
    const intervalMilliseconds = rebalanceFrequencyValue * {
      Minutes: 60 * 1000,
      Hours: 60 * 60 * 1000,
      Days: 24 * 60 * 60 * 1000,
      Weeks: 7 * 24 * 60 * 60 * 1000,
      Months: 30 * 24 * 60 * 60 * 1000
    }[rebalanceFrequency];
    
    const intervalTime = intervalMilliseconds / 1000;
  
    if (duration > intervalTime) {
      showAlert({ severity: 'error', message: `Duration exceeds the available execution time. 
        Available time: ${duration} seconds.` });
      setSubmitLoading(false);
      return;
    }
  
  
    const rebalanceData = {
      assets_type: assetFilter,
      target_weights: targetWeights,
      tolerance,
      initial_balance_notional: parseInt(BalanceNotional, 10),
      is_floating: isFloating,
      account_id: selectedAccount[0],
      exchange_name: selectedAccount[1],
      counter_asset: counterAsset,
      interval: intervalTime,
    };

    setChildOrders(child_orders);
    setRebalanceData(rebalanceData);
    setShowConfirmation(true);
  };

  const confirmRebalance = async () => {
    if (!rebalanceData) {
      showAlert({ severity: 'error', message: 'Rebalance data not found.' });
      return;
    }
    
    setSubmitLoading(true);
    setShowConfirmation(false);
    try {
      const response = await startRebalance(rebalanceData);
      if (response.success) {
        showAlert({ severity: 'success', message: response.message });
        setTaskId(response.task_id);
        setOrderId(response.parent_order_id);
        // window.location.reload();
      } else {
        showAlert({ severity: 'error', message: response.message });
      }
    } catch (error) {
      showAlert({ severity: 'error', message: 'Error occurred during rebalance process.' });
    } finally {
      setSubmitLoading(false);
    }
  };

  
  const handleStopRebalanceConfirmation = () => {
    setShowStopConfirmation(true);
  };
  
  const confirmStopRebalance = async () => {
    setStopLoading(true);
    setShowStopConfirmation(false);
  
    try {
      let cancelOrderSuccess = true;
      try {
        const cancelOrderResponse = await cancelMultiOrder(orderId);
        if (cancelOrderResponse.message) {
          showAlert({ severity: 'success', message: 'Order canceled successfully.' });
        } else {
          showAlert({ severity: 'warning', message: 'Failed to cancel order. Order might be already complete.' });
          cancelOrderSuccess = false;
        }
      } catch (error) {
        showAlert({ severity: 'warning', message: 'Order cancellation failed. Order might already be complete.' });
        cancelOrderSuccess = false;
      }
  
      if (!taskId) {
        await fetchRebalanceStatus();
  
        if (!taskId) {
          showAlert({ severity: 'error', message: 'Task ID not found. Unable to stop the rebalance task.' });
          return;
        }
      }
  
      const stopRebalanceResponse = await stopRebalance(taskId);
      if (stopRebalanceResponse.message) {
        showAlert({ severity: 'success', message: stopRebalanceResponse.message });
        setStatus('stopped');
        window.location.reload();
      } else {
        showAlert({ severity: 'error', message: 'Failed to stop rebalance.' });
      }
    } catch (error) {
      showAlert({ severity: 'error', message: 'Error while stopping the rebalance.' });
    } finally {
      setStopLoading(false);
    }
  };

  const calculateRebalanceInterval = () => {
    if (!timeStart || !nextRebalance) {
      console.warn("timeStart or nextRebalance is missing.");
      return { calculatedFrequencyValue: 0, calculatedFrequencyUnit: "Minutes" };
    }

    const timeStartDate = new Date(timeStart);
    const nextRebalanceDate = new Date(nextRebalance);
    const timeDifferenceInMs = nextRebalanceDate - timeStartDate;

    let calculatedFrequencyValue = 0;
    let calculatedFrequencyUnit = "Minutes";

    const timeInMinutes = timeDifferenceInMs / (1000 * 60);
    const timeInHours = timeDifferenceInMs / (1000 * 60 * 60);
    const timeInDays = timeDifferenceInMs / (1000 * 60 * 60 * 24);
    const timeInWeeks = timeDifferenceInMs / (1000 * 60 * 60 * 24 * 7);
    const timeInMonths = timeDifferenceInMs / (1000 * 60 * 60 * 24 * 30);

    if (timeInMinutes < 60) {
      calculatedFrequencyValue = Math.round(timeInMinutes);
      calculatedFrequencyUnit = "Minutes";
    } else if (timeInHours < 24) {
      calculatedFrequencyValue = Math.round(timeInHours);
      calculatedFrequencyUnit = "Hours";
    } else if (timeInDays < 7) {
      calculatedFrequencyValue = Math.round(timeInDays);
      calculatedFrequencyUnit = "Days";
    } else if (timeInWeeks < 4) {
      calculatedFrequencyValue = Math.round(timeInWeeks);
      calculatedFrequencyUnit = "Weeks";
    } else {
      calculatedFrequencyValue = Math.round(timeInMonths);
      calculatedFrequencyUnit = "Months";
    }

    return { calculatedFrequencyValue, calculatedFrequencyUnit };
  };

  useEffect(() => {
    if (inProgress) {
      const { calculatedFrequencyValue, calculatedFrequencyUnit } = calculateRebalanceInterval();
      setRebalanceFrequencyValue(calculatedFrequencyValue);
      setRebalanceFrequency(calculatedFrequencyUnit);
    }
  }, [inProgress, rebalanceData]);

  const calculateNextRebalance = () => {
    const now = new Date();
    const multiplier = rebalanceFrequencyValue;
    let nextTime;

    const daysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();

    switch (rebalanceFrequency) {
    case 'Minutes':
      nextTime = new Date(now.getTime() + multiplier * 60 * 1000);
      break;
    case 'Hours':
      nextTime = new Date(now.getTime() + multiplier * 60 * 60 * 1000);
      break;
    case 'Days':
      nextTime = new Date(now.getTime() + multiplier * 24 * 60 * 60 * 1000);
      break;
    case 'Weeks':
      nextTime = new Date(now.getTime() + multiplier * 7 * 24 * 60 * 60 * 1000);
      break;
    case 'Months': {
      let tempDate = new Date(now);
      for (let i = 0; i < multiplier; i += 1) {
        const daysToAdd = daysInMonth(tempDate.getFullYear(), tempDate.getMonth());
        tempDate = new Date(tempDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000);
      }
      nextTime = tempDate;
      break;
    }
    default:
      return
    }

    setNextRebalance(nextTime.toISOString());
  };

  useEffect(() => {
    if (!inProgress) {
      calculateNextRebalance();
    }
  }, [rebalanceFrequency, rebalanceFrequencyValue]);

  const getSymbolColor = (index) => {
    if (index >= 10) {
      return {
        color: 'rgb(0, 69, 25)',
        backgroundColor: 'rgb(0, 69, 25)',
      };
    }
    return {
      color: chartColors[index],
      backgroundColor: chartColors[index],
    };
  };

  const filterCombinedAllAssetsDoughnut = assetsToUse.filter(asset => {
    const symbolOrPair = asset.symbol || asset.pair;
    return symbolOrPair && !['USD', 'USDT', 'USDC'].includes(symbolOrPair);
  });

  const normalizedAssets = filterCombinedAllAssetsDoughnut.reduce((acc, asset) => {
    const symbolOrPair = (asset.symbol || asset.pair).replace(/-USDT|-USD|-USDC|-PERP$/, '');
    const hasTargetWeight = Object.keys(targetWeights).includes(symbolOrPair) && targetWeights[symbolOrPair] !== 0;
    
    if (!acc.find(a => a.normalizedSymbol === symbolOrPair) && hasTargetWeight) {
      acc.push({ ...asset, normalizedSymbol: symbolOrPair });
    }
    return acc;
  }, []);
  
  const doughnutDatatarget = {
    labels: normalizedAssets.map(asset => asset.normalizedSymbol),
    datasets: [
      {
        label: 'Target Weight',
        data: normalizedAssets.map(asset => {
          return targetWeights[asset.normalizedSymbol] || 0;
        }),
        backgroundColor: normalizedAssets.map((_, index) => getSymbolColor(index).backgroundColor),
      },
    ],
  };

  const handlePairChange = async (index, selectedPair) => {
    const updatedAssets = [...ourAssets];
    const symbol = selectedPair.split('-')[0]; 
    updatedAssets[index] = {
      ...updatedAssets[index],
      pair: selectedPair,
    };
    setOurAssets(updatedAssets);
    await fetchPairPrice(selectedPair);
    setTargetWeights((prevWeights) => ({
      ...prevWeights,
      [symbol]: prevWeights[symbol] || 0,
    }));
  };
  const handleAddRow = () => {
    setOurAssets([...ourAssets, { pair: '', targetWeight: '', currentNotional: 0 }]);
  };
  const handleRemoveRow = (index) => {
    const updatedAssets = ourAssets.filter((_, idx) => idx !== index);
    const removedSymbol = ourAssets[index].pair ? ourAssets[index].pair.split('-')[0] : ourAssets[index].symbol;
  
    setTargetWeights((prevWeights) => {
      const { [removedSymbol]: _, ...remainingWeights } = prevWeights;
      return remainingWeights;
    });
  
    setOurAssets(updatedAssets);
  };
  
  const selectedAccountData = [selectedAccountFull];
  const transformedAccountData = {
    [selectedAccountFull.account_name]: {
      displayName: `${selectedAccountFull.exchange_name} - ${selectedAccountFull.account_name}`,
      exchangeName: selectedAccountFull.exchange_name,
      id: selectedAccountFull.account_id,
      name: selectedAccountFull.account_name
    }
  };
  const transformedBalanceData = {
    [selectedAccountFull.account_id]: {
      account_id: selectedAccountFull.account_id,
      account_name: selectedAccountFull.account_name,
      assets: selectedAccountFull.assets,
      exchange_name: selectedAccountFull.exchange_name,
      timestamp_millis: selectedAccountFull.timestamp_millis,
      user_id: selectedAccountFull.user_id,
      username: selectedAccountFull.username,
    }
  }
  
  const accounts = Object.keys(balanceData).map(accountId => ({
    id: accountId,
    name: balanceData[accountId].account_name || accountId, 
  }));

  const isRebalanceButtonDisabled = loading || submitLoading || inProgress || totalTargetNotional === '0.00';
  const isStopButtonDisabled = loading || stopLoading || !inProgress;
  
  const getRebalanceButtonText = () => {
    if (submitLoading) return 'Starting...';
    if (inProgress) return 'Rebalance in Progress';
    return 'Rebalance Now';
  };
  
  const getStopButtonText = () => {
    if (stopLoading) return 'Stopping...';
    return 'Stop Rebalance';
  };

  const getFormattedTotalDiffColor = (diff) => {
    if (diff > 0) return 'green';
    if (diff < 0) return 'red';
    return '#fff';
  };


  const calculateCashCurrentNotional = () => {
    const selectedAccountBalances = balanceData[selectedAccount[0]];
    if (!selectedAccountBalances) return 0;
    const cashAssets = ['USDT', 'USDC', 'USD'];
    return cashAssets.reduce((total, asset) => {
      const assetData = selectedAccountBalances[asset];
      if (assetData && assetData.length > 0) {
        return total + (assetData[0].notional || 0);
      }
      return total;
    }, 0);
  };
  
  const cashCurrntNotional = calculateCashCurrentNotional();
  const cashTargetWeight = 100 - totalTargetWeight;
  const reverseTotalProposedTradeNotional = -totalProposedTradeNotional;
  const cashTargetNotional = cashCurrntNotional + reverseTotalProposedTradeNotional;

  useEffect(() => {
    if (!inProgress && totalCurrentNotional) {
      setBalanceNotional(parseFloat(totalCurrentNotional).toFixed(2));
    }
  }, [totalCurrentNotional, inProgress]);

  const handleClearFields = () => {
    setTargetWeights({});
    setBalanceNotional(totalCurrentNotional.toFixed(2));
    setTolerance(1);
    setIsFloating(false);
    setRebalanceFrequencyValue(1);
    setRebalanceFrequency("Hours");
    setCounterAsset('USDT');
    setAssetFilter("spot");
    setPositionTypes({});
    setOurAssets([]);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      const loadData = async () => {
        if (selectedAccount?.[0] === 'All Accounts') {
          return;
        }
  
        try {
          setLoading(true);
          await fetchRebalanceStatus();
        } catch (error) {
          showAlert({ severity: 'info', message: 'Rebalance not in progress.' });
        } finally {
          setLoading(false);
        }
      };
  
      loadData();
    }, 1500);
  
    return () => {
      clearTimeout(handler);
    };
  }, [selectedAccount, assetFilter]);
  
  useEffect(() => {
    if (inProgress && orderId) {
      loadOrderData(orderId);
    }
  }, [inProgress, orderId]);
  
  useEffect(() => {
    let progressInterval;
  
    const fetchProgressPeriodically = async () => {
      if (inProgress && orderId) {
        try {
          await loadOrderData(orderId);
        } catch (error) {
          console.error("Error fetching progress:", error);
        }
      }
    };
  
    if (inProgress) {
      progressInterval = setInterval(fetchProgressPeriodically, 15000);
    } else {
      clearInterval(progressInterval);
    }
  
    return () => {
      clearInterval(progressInterval);
    };
  }, [inProgress, orderId]);


  const FILTER_OPTIONS = [
    { value: "spot", label: "Spot" },
    { value: "margin", label: "Margin" },
    { value: "perp", label: "Perpetual" },
  ];

  useEffect(() => {
    const updatedPositionTypes = {};
    Object.entries(targetWeights).forEach(([symbolOrPair, weight]) => {
      updatedPositionTypes[symbolOrPair] = weight >= 0 ? 'long' : 'short';
    });
    setPositionTypes(updatedPositionTypes);
  }, [targetWeights]);

  return loading ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
      <ScaleLoader color="#f39c12" />
    </Box>
  ) : (
    <Box sx={{ padding: '5px', color: '#fff' }}>
      <Box sx={{ display: 'flex', marginBottom: '5px' }}>
        <Typography variant="h6">Rebalance Portfolio</Typography>
        <Tooltip arrow title="Clear all fields">
          <IconButton
            color="primary"
            sx={{
              color: '#f39c12',
              borderRadius: '50%',
              marginLeft: '10px',
              marginTop: '-7px',
            }}
            onClick={handleClearFields}
          >
            <CachedIcon />
          </IconButton>
        </Tooltip>
      </Box> 
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '5px' }}>
        <TextField
          autoComplete="off"
          disabled={inProgress}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            min: 0,
            style: { 
              backgroundColor: '#2c2c2c', 
              color: '#fff', 
              padding: '5px',
            }
          }}
          label="Tolerance(%)"
          sx={{ marginRight: '10px', width: '90px' }}
          type="text"
          value={tolerance}
          onChange={!inProgress ? handleToleranceChange : null}
        />
        <TextField
          autoComplete="off"
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            min: 0,
            style: { 
              backgroundColor: '#2c2c2c', 
              color: '#fff', 
              padding: '5px',
            }
          }}
          label={`Balance Notional (${counterAsset})`}
          sx={{ marginRight: '10px', width: '150px' }}
          type="text"
          value={BalanceNotional}
          onChange={!inProgress ? handleBalanceNotionalChange : null}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isFloating}
              color="primary"
              disabled={inProgress}
              onChange={(e) => setIsFloating(e.target.checked)}
            />
          }
          label="Enable Floating Balance Notional (+5%/-5%)"
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '5px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ marginRight: '10px' }} variant="body1">Rebalance Frequency:</Typography>
          <TextField
            autoComplete="off"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              min: 0,
              style: { 
                backgroundColor: '#2c2c2c', 
                color: '#fff', 
                padding: '5px',
              }
            }}
            sx={{ marginRight: '10px', width: '80px' }}
            type="text"
            value={rebalanceFrequencyValue}
            onChange={!inProgress ? (e) => setRebalanceFrequencyValue(e.target.value) : null}
          />
          <FormControl sx={{ width: '100px' }}>
            <InputLabel id="rebalance-frequency-label">Time Unit</InputLabel>
            <Select
              label="Rebalance Frequency"
              labelId="rebalance-frequency-label"
              value={rebalanceFrequency}
              onChange={!inProgress ? (e) => setRebalanceFrequency(e.target.value) : null}
            >
              <MenuItem value="Minutes">Minutes</MenuItem>
              <MenuItem value="Hours">Hours</MenuItem>
              <MenuItem value="Days">Days</MenuItem>
              <MenuItem value="Weeks">Weeks</MenuItem>
              <MenuItem value="Months">Months</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <FormControl sx={{ marginLeft: "5px", minWidth: "130px" }}>
          <InputLabel id="asset-filter-label">Assets Type</InputLabel>
          <Select
            label="Assets Type"
            labelId="asset-filter-label"
            value={assetFilter}
            onChange={handleFilterChange}
          >
            {FILTER_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
          {assetFilter === "perp" && (
            <Tooltip
              arrow
              title={
                <Typography sx={{ color: '#fff', fontStyle: 'italic', textAlign: 'center' }} variant="body2">
                  For `Perpetual` asset type, all calculations are based on absolute values. 
                  Negative values are not used for Balance Notional or Total calculations, 
                  but positions are displayed as `Net Short` or `Net Long`.
                </Typography>
              }              
            >
              <ContactSupportIcon
                sx={{
                  color: '#f39c12',
                  cursor: 'pointer',
                  fontSize: '24px',
                  marginLeft: '5px',
                }}
              />
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '-5px', marginBottom: '-5px' }}>
        <Typography sx={{ color: '#fff' }} variant="body2">
        Next Rebalance: {formatDateTime(nextRebalance)}
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={showCashRow}
              color="primary"
              onChange={(e) => setShowCashRow(e.target.checked)}
            />
          }
          label="Show Cash Row"
          sx={{ marginLeft: '5px' }}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <TableContainer sx={{ width: '65%', maxHeight: (!inProgress ? filteredCombinedAssets.length : 
          filteredInprogressAssets.length) > 5 ? '423px' : 'auto', overflowY: 
          (!inProgress ? filteredCombinedAssets.length : filteredInprogressAssets.length) > 5 ? 'auto' : 'visible' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {RebalanceAssetsColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{ color: '#fff', border: '1px solid #333', minWidth: column.minWidth, textAlign: column.align }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(!inProgress ? filteredCombinedAssets : filteredInprogressAssets).map((asset) => {
                const {
                  currentPrice,
                  currentWeight,
                  targetNotional,
                  currentNotional,
                  proposedTradeNotional,
                  proposedTradeQuantity,
                  diff,
                } = handleRowCalculations(asset, currentPrices, BalanceNotional);

                const getTargetWeightInputWithButtons = (asset) => {
                  const symbolOrPair = asset.symbol ? trimSymbol(asset.symbol) : asset.pair;
                  const positionType =
                    positionTypes[symbolOrPair] || (targetWeights[symbolOrPair] < 0 ? "short" : "long");
                  const isRebalanceInProgress = status === "in_progress";
                
                  const handlePositionTypeChange = (type) => {
                    setPositionTypes((prev) => ({
                      ...prev,
                      [symbolOrPair]: type,
                    }));
                    const currentWeight = parseFloat(targetWeights[symbolOrPair] || 0);
                    const adjustedWeight = type === "long" ? Math.abs(currentWeight) : -Math.abs(currentWeight);
                    setTargetWeights((prev) => ({
                      ...prev,
                      [symbolOrPair]: adjustedWeight,
                    }));
                  };
                
                  return (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        autoComplete="off"
                        disabled={isRebalanceInProgress}
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          min: 0,
                          max: 100,
                          style: {
                            backgroundColor: "#2c2c2c",
                            color: "#fff",
                            padding: "5px",
                          },
                        }}
                        type="text"
                        value={targetWeights[symbolOrPair] === undefined ? '' : Math.abs(targetWeights[symbolOrPair])}
                        onChange={(e) => handleInputChange(symbolOrPair, e)}
                      />
                      {(assetFilter === "perp" || assetFilter === "margin") && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "15px",
                            marginRight: "-10px",
                          }}
                        >
                          <Tooltip title="Set position to Long">
                            <Button
                              color="success"
                              disabled={isRebalanceInProgress}
                              size="small"
                              sx={{
                                minWidth: "24px",
                                height: "24px",
                                padding: "2px",
                                fontSize: "12px",
                              }}
                              variant={positionType === "long" ? "contained" : "outlined"}
                              onClick={() => handlePositionTypeChange("long")}
                            >
                              <ArrowUpward fontSize="inherit" />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Set position to Short">
                            <Button
                              color="error"
                              disabled={isRebalanceInProgress}
                              size="small"
                              sx={{
                                marginTop: "3px",
                                minWidth: "24px",
                                height: "24px",
                                padding: "2px",
                                fontSize: "12px",
                              }}
                              variant={positionType === "short" ? "contained" : "outlined"}
                              onClick={() => handlePositionTypeChange("short")}
                            >
                              <ArrowDownward fontSize="inherit" />
                            </Button>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  );
                };
                const getTableCellContent = (column, asset, diff, diffStyle) => {
                  const isPerp = assetFilter === "perp"; 
                
                  switch (column.id) {
                    case 'symbol':
                      return trimSymbol(asset.symbol);
                    case 'targetWeight':
                      return getTargetWeightInputWithButtons(asset);
                    case 'currentWeight':
                      return <span>{currentWeight ? `${currentWeight}%` : '0.00%'}</span>;
                    case 'diff':
                      return <span style={diffStyle}>{`${diff}%`}</span>;
                    case 'currentQuantity':
                      return `${(currentNotional / currentPrice).toFixed(2)} ${trimSymbol(asset.symbol)}`;
                    case 'currentNotional': {
                      if (isPerp) {
                        const displayValue = Math.abs(currentNotional).toFixed(2);
                        const positionType = currentNotional < 0 ? 'Net Short' : 'Net Long';
                        const positionStyle = {
                          color: currentNotional < 0 ? 'red' : 'green',
                          fontWeight: 'bold',
                          textAlign: 'center',
                        };

                        return (
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <span>{displayValue} {counterAsset}</span>
                            <span style={positionStyle}>{positionType}</span>
                          </div>
                        );
                      }
                      return `${currentNotional.toFixed(2)} ${counterAsset}`;
                    }
                    case 'targetNotional': {
                      if (isPerp) {
                        const displayValue = Math.abs(targetNotional).toFixed(2);
                        const positionType = targetNotional < 0 ? 'Net Short' : 'Net Long';
                        const positionStyle = {
                          color: targetNotional < 0 ? 'red' : 'green',
                          fontWeight: 'bold',
                          textAlign: 'center',
                        };

                        return (
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <span>{displayValue} {counterAsset}</span>
                            <span style={positionStyle}>{positionType}</span>
                          </div>
                        );
                      }
                      return `${targetNotional.toFixed(2)} ${counterAsset}`;
                    }
                    case 'targetQuantity':
                      return `${(Math.abs(targetNotional) / currentPrice).toFixed(2)} ${trimSymbol(asset.symbol)}`;
                    case 'proposedTradeNotional': {
                      const displayValue = Math.abs(proposedTradeNotional).toFixed(2);
                      return `${displayValue} ${counterAsset}`;
                    }
                    case 'proposedTradeQuantity': {
                      const displayValue = Math.abs(proposedTradeQuantity).toFixed(2);
                      return `${displayValue} ${trimSymbol(asset.symbol)}`;
                    }
                    default:
                      return asset[column.id] || 0;
                  }
                };                

                let diffStyle = {};
                if (diff > 0) {
                  diffStyle = { color: 'green' };
                } else if (diff < 0) {
                  diffStyle = { color: 'red' };
                }
                
                return (
                  <TableRow key={asset.symbol}>
                    {RebalanceAssetsColumns.map((column) => (
                      <TableCell key={column.id} sx={{ color: '#fff', border: 
                      '1px solid #333', textAlign: column.align }}>
                        {getTableCellContent(column, asset, diff, diffStyle)}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
              {!inProgress && ourAssets.map((asset, index) => {
                const fullSymbolOrPair = asset.pair || '';
                const symbolOrPair = fullSymbolOrPair.replace(/-USDT|-USDC|-USD$/, '');

                const currentPrice = currentPrices[fullSymbolOrPair] || 0;
                const currentNotional = asset.currentNotional || 0;
                const totalCurrentNotional = combinedAssets.reduce((sum, asset) => 
                  sum + (asset.currentNotional || 0), 0) || 1;

                const currentWeight = calculateCurrentWeight(currentNotional, totalCurrentNotional);
                const targetWeight = parseFloat(targetWeights[symbolOrPair] || 0);
                const { proposedTradeNotional, proposedTradeQuantity, targetNotional } = calculateProposedTrade(
                  targetWeight,
                  currentNotional,
                  currentPrice
                );

                const diff = calculateDiff(targetWeight, currentWeight);
                let diffStyle = {};
                if (diff > 0) {
                  diffStyle = { color: 'green' };
                } else if (diff < 0) {
                  diffStyle = { color: 'red' };
                }

                const getTargetWeightValue = (symbolOrPair, targetWeights) => {
                  if (!symbolOrPair) return '';
                
                  const weight = targetWeights[symbolOrPair.split('-')[0]];
                  const positionType = weight < 0 ? 'short' : 'long';
                  const adjustedWeight = Math.abs(weight || '');
                
                  const handlePositionTypeChange = (type) => {
                    setPositionTypes((prev) => ({
                      ...prev,
                      [symbolOrPair]: type,
                    }));
                
                    const updatedWeight = type === 'long' ? adjustedWeight : -adjustedWeight;
                    setTargetWeights((prev) => ({
                      ...prev,
                      [symbolOrPair.split('-')[0]]: updatedWeight,
                    }));
                  };

                  return (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        autoComplete='off'
                        disabled={!symbolOrPair}
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                          min: 0,
                          max: 100,
                          style: { 
                            backgroundColor: '#2c2c2c', 
                            color: '#fff', 
                            padding: '5px',
                          },
                        }}
                        type="text"
                        value={adjustedWeight}
                        onChange={(e) => handleInputChange(symbolOrPair.split('-')[0], e)}
                      />
                      {(assetFilter === "perp" || assetFilter === "margin") && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '15px' }}>
                          <Tooltip title="Set position to Long">
                            <Button
                              color="success"
                              disabled={!symbolOrPair}
                              size="small"
                              sx={{
                                minWidth: '24px',
                                height: '24px',
                                padding: '2px',
                                fontSize: '12px',
                              }}
                              variant={positionType === 'long' ? 'contained' : 'outlined'}
                              onClick={() => handlePositionTypeChange('long')}
                            >
                              <ArrowUpward fontSize="inherit" />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Set position to Short">
                            <Button
                              color="error"
                              disabled={!symbolOrPair}
                              size="small"
                              sx={{
                                marginTop: '3px',
                                minWidth: '24px',
                                height: '24px',
                                padding: '2px',
                                fontSize: '12px',
                              }}
                              variant={positionType === 'short' ? 'contained' : 'outlined'}
                              onClick={() => handlePositionTypeChange('short')}
                            >
                              <ArrowDownward fontSize="inherit" />
                            </Button>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  );
                };
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow key={index}>
                    <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                      <PairSelector
                        multiOrder
                        accounts={transformedAccountData}
                        balances={transformedBalanceData}
                        favourites={favouritePairs}
                        pairs={tokenPairs}
                        selectedAccounts={selectedAccountData.map((acc) => acc.account_name)}
                        selectedPairName={symbolOrPair}
                        setFavourites={setFavouritePairs}
                        setSelectedPair={(pair) => handlePairChange(index, pair.label)}
                        showAlert={showAlert}
                      />
                    </TableCell>
                    <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                      {getTargetWeightValue(symbolOrPair, targetWeights)}
                    </TableCell>
                    <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                      {currentWeight ? `${currentWeight}%` : '0.00%'}
                    </TableCell>
                    <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                      {targetNotional ? `${targetNotional.toFixed(2)} ${counterAsset}` : `0.00 ${counterAsset}`}
                    </TableCell>
                    <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                      {currentNotional ? `${currentNotional.toFixed(2)} ${counterAsset}` : `0.00 ${counterAsset}`}
                    </TableCell>
                    <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                      {targetNotional && currentPrice ? `${(targetNotional / currentPrice).toFixed(2)} 
                      ${trimSymbol(symbolOrPair)}` : '0.00'}
                    </TableCell>
                    <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                      {currentNotional && currentPrice ? `${(currentNotional / currentPrice).toFixed(2)} 
                      ${trimSymbol(symbolOrPair)}` : `0.00 ${trimSymbol(symbolOrPair)}`}
                    </TableCell>
                    <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                      {proposedTradeNotional ? `${proposedTradeNotional.toFixed(2)} 
                      ${counterAsset}` : `0.00 ${counterAsset}`}
                    </TableCell>
                    <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                      {proposedTradeQuantity ? `${proposedTradeQuantity.toFixed(2)} 
                      ${trimSymbol(symbolOrPair)}` : '0.00'}
                    </TableCell>
                    <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                      <span style={diffStyle}>{`${diff}%`}</span>
                    </TableCell>
                    <IconButton color="secondary" onClick={() => handleRemoveRow(index)}>
                      <Delete />
                    </IconButton>
                  </TableRow>
                );                
              })}
              {showCashRow && (
                <TableRow>
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'left' }}>
                    Cash
                  </TableCell>
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                    {cashTargetWeight}%
                  </TableCell>
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }} />
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                    {cashTargetNotional.toFixed(2)} {counterAsset}
                  </TableCell>
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                    {cashCurrntNotional.toFixed(2)} {counterAsset}
                  </TableCell>
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }} />
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }} />
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                    {-formattedTotalProposedTradeNotional} {counterAsset}
                  </TableCell>
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }} />
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }} />
                </TableRow>
              )}
              <TableRow>
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'left' }}>
                    Total
                </TableCell>
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                  {showCashRow
                    ? `${(parseFloat(totalTargetWeight) + parseFloat(cashTargetWeight)).toFixed(2)}%`
                    : `${parseFloat(totalTargetWeight).toFixed(2)}%`}
                </TableCell>
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                  {totalCurrentWeight}%
                </TableCell>
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                  {totalTargetNotional} {counterAsset}
                </TableCell>
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                  {totalCurrentNotional.toFixed(2)} {counterAsset}
                </TableCell>
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }} />
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }} />
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                  {formattedTotalProposedTradeNotional} {counterAsset}
                </TableCell>
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }} />
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                  <span style={{ color: getFormattedTotalDiffColor(formattedTotalDiff) }}>
                    {formattedTotalDiff}%
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ width: '30%', display: 'flex', marginLeft: '50px' }}> 
          <Box sx={{ width: '80%', height: '300px' }}>
            <Doughnut data={doughnutDatatarget} options={{ maintainAspectRatio: false }} />
            <Typography align="center">Target Weight</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '20px', marginTop: '20px' }}>
        <FormControl sx={{ width: '150px' }}>
          <InputLabel id="counter-asset-label">Counter Asset</InputLabel>
          <Select
            label="Counter Asset"
            labelId="counter-asset-label"
            value={counterAsset}
            onChange={!inProgress ? (e) => setCounterAsset(e.target.value) : null}
          >
            <MenuItem value="USDT">USDT</MenuItem>
            <MenuItem value="USD">USD</MenuItem>
            <MenuItem value="USDC">USDC</MenuItem>
          </Select>
          <Typography variant="body1">
            Balance of {counterAsset}: {msAndKs(truncate(getAssetQuantityForCounterAsset()), 2)} {counterAsset}
          </Typography>
          <Typography variant="body1">
            Total Balance: {totalCurrentNotionalAllFormatted} {counterAsset}
          </Typography>
        </FormControl>

        {!inProgress ? (
          <Tooltip arrow title="Click to add a new asset row for rebalancing">
            <Button
              color="primary"
              disabled={inProgress}
              sx={{ marginLeft: '30px', marginTop: '8px' }}
              variant="contained"
              onClick={handleAddRow}
            >
              Add row
            </Button>
          </Tooltip>
        ) : (
          <Box
            sx={{
              backgroundColor: '#333',
              padding: '10px',
              borderRadius: '5px',
              marginBottom: '20px',
              width: '250px',
              marginLeft: '30px',
              marginTop: '8px',
            }}
          >
            <Typography sx={{ color: '#fff' }} variant="body1">
              Rebalance is in progress.
            </Typography>
            <Typography sx={{ color: '#fff' }} variant="body2">
              Next Rebalance: {formatDateTime(nextRebalance)}
            </Typography>
            <Typography align="center" marginTop="5px" sx={{ color: '#fff' }} variant="body2">
              Rebalance Progress: {rebalanceProgress.toFixed(2)}%
            </Typography>
            <LinearProgress
              sx={{ height: '10px', borderRadius: '5px', backgroundColor: '#444', marginBottom: '10px' }}
              value={rebalanceProgress}
              variant="determinate"
            />
            <Button
              color="primary"
              sx={{ marginTop: '10px' }}
              variant="contained"
              onClick={() => openInNewTab(`${BASEURL}/multi_order/${orderId}`)}
            >
              View Order
            </Button>
          </Box>
        )}
      </Box>
      <Box sx={{ marginTop: '20px', display: 'flex', gap: '10px' }}>
        <Button
          disabled={isRebalanceButtonDisabled}
          sx={{ backgroundColor: '#f39c12', width: '200px' }}
          variant="contained"
          onClick={handleRebalance}
        >
          {getRebalanceButtonText()}
        </Button>
        <Button
          disabled={isStopButtonDisabled}
          sx={{ backgroundColor: '#e74c3c', width: '200px' }}
          variant="contained"
          onClick={handleStopRebalanceConfirmation}
        >
          {getStopButtonText()}
        </Button>
      </Box>
      {showConfirmation && (
        <Modal
          open={showConfirmation}
          onClose={() => {
            setShowConfirmation(false);
            setSubmitLoading(false);
          }}
        >
          <Box
            sx={{
              backgroundColor: '#2c2c2c',
              color: '#fff',
              padding: '20px',
              borderRadius: '8px',
              width: '500px',
              maxHeight: '80vh',
              overflowY: 'auto',
              margin: 'auto',
              marginTop: '10%',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
              position: 'relative'
            }}
          >
            <IconButton
              sx={{ 
                position: 'absolute', 
                top: '10px', 
                right: '10px', 
                color: '#fff' 
              }}
              onClick={() => {
                setShowConfirmation(false);
                setSubmitLoading(false);
              }}
            >
              ✖️
            </IconButton>
            <Typography sx={{ marginBottom: '20px', textAlign: 'center' }} variant="h6">
              Confirm Your Trades
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: '#fff' }}>Pair</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Side</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {childOrders.map((order) => {
                  const baseSymbol = order.pair ? order.pair.split('-')[0] : order.symbol;
                  const fullSymbol = `${baseSymbol}-USDT`; 
                  const currentPrice = currentPrices[fullSymbol] || currentPrices[baseSymbol] || 0;
                  const uniqueKey = `${order.pair}-${order.side}`;

                  let sideDescription = order.side;
                  if (assetFilter === 'margin' || assetFilter === 'perp') {
                    sideDescription = order.side === 'buy' ? 'buy (long)' : 'sell (short)';
                  }

                  return (
                    <TableRow key={uniqueKey}>
                      <TableCell sx={{ color: '#fff' }}>{order.pair}</TableCell>
                      <TableCell
                        sx={{
                          color: order.side === 'buy' ? 'green' : 'red',
                          fontWeight: 'bold',
                        }}
                      >
                        {sideDescription}
                      </TableCell>
                      <TableCell sx={{ color: '#fff' }}>
                        {(order.base_asset_qty * currentPrice).toFixed(2)} {counterAsset}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                sx={{ backgroundColor: '#f39c12', color: '#fff' }}
                variant="contained"
                onClick={confirmRebalance}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
      {showStopConfirmation && (
        <Modal
          open={showStopConfirmation}
          onClose={() => setShowStopConfirmation(false)}
        >
          <Box
            sx={{
              backgroundColor: '#2c2c2c',
              color: '#fff',
              padding: '20px',
              borderRadius: '8px',
              width: '400px',
              margin: 'auto',
              marginTop: '20%',
              textAlign: 'center',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
            }}
          >
            <Typography sx={{ marginBottom: '20px' }} variant="h6">
              Confirm Stop Rebalance
            </Typography>
            <Typography sx={{ marginBottom: '20px' }}>
              Are you sure you want to stop the rebalance process? This action cannot be undone.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
              <Button
                sx={{ backgroundColor: '#e74c3c', color: '#fff' }}
                variant="contained"
                onClick={confirmStopRebalance}
              >
                Yes, Stop
              </Button>
              <Button
                sx={{ backgroundColor: '#2ecc71', color: '#fff' }}
                variant="contained"
                onClick={() => setShowStopConfirmation(false)}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
      {message && <Typography sx={{ marginTop: '20px', color: 'white' }}>{message}</Typography>}
    </Box>
  );
}

export default AccountRebalance;
