/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { useAtom } from 'jotai';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import {
  fetchNoUserOrderEntryFormData,
  fetchOrderEntryFormData,
} from '../../apiServices';
import { ErrorContext } from '../../shared/context/ErrorProvider';
import { Loader } from '../../shared/Loader';
import FavoritePairTabs from './orderEntry/FavoritePairTabs';
import * as FormAtoms from './orderEntry/hooks/useFormReducer';
import { MarketDataProvider } from './orderEntry/MarketDataContext';
import OrderEntryForm from './orderEntry/OrderEntryForm';
import { PriceDataProvider } from './orderEntry/PriceDataContext';
import OrderTable from './OrderTable';
import { PairInfoBar } from './PairInfoBar';
import PredictionCharts from './PredictionCharts';
import PriceCard from './PriceCard';
import { useUserMetadata } from '../../shared/context/UserMetadataProvider';
import useQueryParams from '../../shared/hooks/useQueryParams';

function matchPair(pairs, pairId) {
  const foundPairs = pairs.filter((p) => p.id === pairId);
  return foundPairs.length > 0 ? foundPairs[0] : null;
}

export default function OrderEntryPage() {
  const { setHasError, setErrorContent } = useContext(ErrorContext);
  const [queryParams, setQueryParam] = useQueryParams();

  const [selectedPair, setSelectedPair] = useAtom(FormAtoms.selectedPairAtom);
  const [selectedAccounts] = useAtom(FormAtoms.selectedAccountsAtom);
  const [loading, setLoading] = useAtom(FormAtoms.loadingAtom);
  const [initialLoadValue, setInitialLoadValue] = useAtom(
    FormAtoms.initialLoadValueAtom
  );
  const [tokenPairLookup] = useAtom(FormAtoms.tokenPairLookUpAtom);
  const [, setFormPageType] = useAtom(FormAtoms.formPageType);

  const [, setBaseQtyPlaceholder] = useAtom(FormAtoms.baseQtyPlaceholderAtom);
  const [, setQuoteQtyPlaceholder] = useAtom(FormAtoms.quoteQtyPlaceholderAtom);

  const { user } = useUserMetadata();

  const getRelevantExchangeName = (pair) => {
    let exchange = 'Binance';

    const firstSelectedAccountDetails =
      selectedAccounts.length > 0
        ? initialLoadValue.accounts[selectedAccounts[0]]
        : null;

    if (firstSelectedAccountDetails) {
      exchange = firstSelectedAccountDetails.exchangeName;
    } else if (
      Object.keys(tokenPairLookup).length > 0 &&
      tokenPairLookup[pair]
    ) {
      const [firstExchange] = Object.keys(tokenPairLookup[pair])
        .filter((x) => x !== 'MockExchange')
        .sort();
      if (firstExchange) {
        exchange = firstExchange;
      }
    }

    return exchange;
  };

  const pair = selectedPair ? selectedPair.id : queryParams.pair || 'BTC-USDT';
  const exchangeName = getRelevantExchangeName(pair);

  const showAlert = ({ severity, message }) => {
    setErrorContent({ severity, message });
    setHasError(true);
  };

  useEffect(() => {
    setQueryParam('pair', selectedPair && selectedPair.id);
  }, [selectedPair]);

  useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);
      setFormPageType('DashboardPage');
      let data;
      try {
        data = await fetchOrderEntryFormData();
      } catch (e) {
        showAlert({
          severity: 'error',
          message: `Unable to load accounts: ${e.message}`,
        });
        return;
      }

      const pairs = data.pairs.map((p) => {
        return {
          base: p.base,
          exchanges: p.exchanges,
          id: p.name,
          is_contract: p.is_contract,
          is_inverse: p.is_inverse,
          label: p.name,
          market_type: p.market_type,
          quote: p.quote,
        };
      });

      if (pairs && pair.length > 0 && selectedPair === null) {
        let newPair = matchPair(pairs, pair);
        if (!newPair) {
          newPair = matchPair(pairs, 'BTC-USDT');
        }

        if (newPair) {
          setSelectedPair(newPair);
        }

        setQueryParam('pair', newPair ? newPair.id : null);
      }

      const accounts = {};
      data.accounts.forEach((acc) => {
        const scopedAccName =
          acc.user === data.user_id ? acc.name : `${acc.username}/${acc.name}`;
        const displayName = `${acc.exchange} - ${scopedAccName}`;
        accounts[scopedAccName] = {
          displayName,
          id: acc.id,
          name: scopedAccName,
          exchangeName: acc.exchange,
        };
      });

      const indexedStrategies = data.strategies.reduce((obj, item) => {
        obj[item.id] = item;
        return obj;
      }, {});

      const indexedSuperStrategies = data.super_strategies.reduce(
        (obj, item) => {
          obj[item.id] = item;
          return obj;
        },
        {}
      );

      setInitialLoadValue({
        tokenPairs: pairs,
        accounts,
        exchanges: data.exchanges,
        strategies: indexedSuperStrategies,
        trajectories: indexedStrategies,
        superStrategies: indexedSuperStrategies,
        strategyParams: data.strategy_params,
        orderTemplates: data.order_templates,
      });

      setLoading(false);
    };
    const loadNoUserInitialData = async () => {
      setLoading(true);
      let data;
      try {
        data = await fetchNoUserOrderEntryFormData();
      } catch (e) {
        showAlert({
          severity: 'error',
          message: `Unable to load accounts: ${e.message}`,
        });
        return;
      }

      const pairs = data.pairs.map((p) => {
        return {
          base: p.base,
          exchanges: p.exchanges,
          id: p.name,
          is_contract: p.is_contract,
          is_inverse: p.is_inverse,
          label: p.name,
          market_type: p.market_type,
          quote: p.quote,
        };
      });

      if (pairs && pair.length > 0 && selectedPair === null) {
        let newPair = matchPair(pairs, pair);
        if (!newPair) {
          newPair = matchPair(pairs, 'BTC-USDT');
        }

        if (newPair) {
          setSelectedPair(newPair);
        }

        setQueryParam('pair', newPair ? newPair.id : null);
      }

      const indexedStrategies = data.strategies.reduce((obj, item) => {
        obj[item.id] = item;
        return obj;
      }, {});

      const indexedSuperStrategies = data.super_strategies.reduce(
        (obj, item) => {
          obj[item.id] = item;
          return obj;
        },
        {}
      );

      setInitialLoadValue({
        ...initialLoadValue,
        exchanges: data.exchanges,
        tokenPairs: pairs,

        strategies: indexedSuperStrategies,
        trajectories: indexedStrategies,
        superStrategies: indexedSuperStrategies,
        strategyParams: data.strategy_params,
      });

      setLoading(false);
    };

    if (user?.is_authenticated) {
      loadInitialData();
    } else {
      loadNoUserInitialData();
    }
  }, [user]);

  useEffect(() => {
    if (selectedPair) {
      setBaseQtyPlaceholder(selectedPair.base);
      setQuoteQtyPlaceholder(selectedPair.quote);
    }
  }, [selectedPair]);

  if (loading) {
    return (
      <Stack height='100%'>
        <Card>
          <CardContent>
            <Loader />
          </CardContent>
        </Card>
      </Stack>
    );
  }

  return (
    <Stack
      alignItems='stretch'
      direction='row'
      spacing={1}
      style={{ height: '100%' }}
    >
      <Stack direction='column' height='100%' spacing={1} width='75%'>
        <Stack direction='row' height='60%' spacing={1}>
          <PriceDataProvider exchangeName={exchangeName} pair={pair}>
            <Stack
              direction='column'
              spacing={1}
              style={{ width: '75%', height: '100%' }}
            >
              <Card style={{ height: '40px' }}>
                <CardContent style={{ paddingTop: '0px' }}>
                  <FavoritePairTabs
                    exchangeName={exchangeName}
                    FormAtoms={FormAtoms}
                  />
                </CardContent>
              </Card>
              <MarketDataProvider
                exchangeName={exchangeName}
                pair={pair}
                showAlert={showAlert}
              >
                <Card style={{ height: '60px' }}>
                  <CardContent
                    style={{ padding: '8px', height: 'calc(100% - 16px)' }}
                  >
                    <PairInfoBar
                      exchangeName={exchangeName}
                      FormAtoms={FormAtoms}
                      selectedPairName={pair}
                      showAlert={showAlert}
                    />
                  </CardContent>
                </Card>
                <Card height='calc(100% - 60px)'>
                  <CardContent>
                    <PredictionCharts />
                  </CardContent>
                </Card>
              </MarketDataProvider>
            </Stack>
            <Card style={{ width: '25%', height: '100%', overflow: 'auto' }}>
              <CardContent>
                <PriceCard exchangeName={exchangeName} pair={pair} />
              </CardContent>
            </Card>
          </PriceDataProvider>
        </Stack>
        <Card style={{ width: '100%', height: '40%' }}>
          <CardContent>
            <OrderTable FormAtoms={FormAtoms} />
          </CardContent>
        </Card>
      </Stack>
      <Stack direction='column' height='100%' spacing={1} width='25%'>
        <Card>
          <CardContent>
            <OrderEntryForm
              FormAtoms={FormAtoms}
              isAuthenticated={user && user.is_authenticated}
            />
            <Box
              alignItems='center'
              display='flex'
              height='100%'
              justifyContent='center'
            >
              <Typography variant='h6'>Login to submit orders</Typography>
            </Box>
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
}
