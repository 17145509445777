import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { getCallDynamic, getOrderSearchData } from '../../apiServices';

function OrderSearch({
  setOrders,
  statusHighlight,
  typeFilter,
  setLoading,
  url = `/ems/get_order_table_rows`,
  urlHandler = () => {},
  setSearchHandler = () => {},
  sharedTablePageNumber = null,
}) {
  const [dateFrom, setDateFrom] = useState(undefined);
  const [dateTo, setDateTo] = useState(undefined);
  const [accountValue, setAccountValue] = useState(null);
  const [pairValue, setPairValue] = useState(null);
  const [sideValue, setSideValue] = useState('');
  const [strategyValue, setStrategyValue] = useState(null);
  const [marketTypeValue, setMarketTypeValue] = useState([]);

  const [initialAccounts, setInitialAccounts] = useState([]);
  const [initialPairs, setInitialPairs] = useState([]);
  const [initialStrategies, setInitialStrategies] = useState([]);

  useEffect(() => {
    const loadSearchData = async () => {
      try {
        const data = await getOrderSearchData();
        setInitialAccounts(data.accounts);
        setInitialPairs(data.pairs);
        setInitialStrategies(data.strategies);
      } catch (e) {
        console.error(e);
      }
    }
    loadSearchData();
  }, []);

  const handleFilter = async ({statusVal = "", orderTypes = "", pageNumber = 0}) => {
    let strategyKeyVal = {}
    if(strategyValue){
      switch(strategyValue[2]){
      case 'Strategy':
        strategyKeyVal = { 'super_strategy': strategyValue[0] }
        break;
      case 'Trajectory':
        strategyKeyVal = { 'strategy': strategyValue[0] }
        break;
      default:
        break;
      }

    }

    const params = {
      'accounts__name': accountValue !== null ? accountValue[0] : '',
      'pair': pairValue || '',
      'side': sideValue,
      'status': statusVal,
      ...strategyKeyVal,
      'type': orderTypes,
    };

    if (dateFrom) {
      params.created_at__gte = dateFrom;
    }
    if (dateTo) {
      params.created_at__lt = dateTo;
    }

    if (marketTypeValue.length > 0) {
      params.market_type = marketTypeValue.join(',');
    }

    if ( pageNumber !== null ) {
      params.page_number = pageNumber;
      params.page_size = 15;
    }

    setLoading(true);

    try {
      const response = await getCallDynamic(url, params);
      await setOrders(response.orders, setLoading(false))
      urlHandler(response);
    } catch (e) {
      setLoading(false)
    }

  };

  const OnAutoCompleteInputChange = (event, newInputValue, reason, setter) => {
    if (reason === 'reset' || reason === 'clear') {
      setter(null);
    } else {
      setter(newInputValue);
    }
  };

  const handleDateChange = (value, dateSetter) => {
    const date = dayjs(value);
    if (date.isValid()) {
      dateSetter(date.format('YYYY-MM-DD'));
    }
  };

  useEffect(() => {
    handleFilter({
      'statusVal': statusHighlight,
      'orderTypes': typeFilter,
      'pageNumber': sharedTablePageNumber
    })
    setSearchHandler(() => () => handleFilter({
      'statusVal': statusHighlight,
      'orderTypes': typeFilter,
      'pageNumber': sharedTablePageNumber
    }));
  }, [statusHighlight, typeFilter, sharedTablePageNumber]);

  return (
    <Box height='100%'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          minHeight="100%"
        >
          <DatePicker
            defaultValue={null}
            label="Date From"
            slotProps={{
              textField: { size: 'small' },
              field: { clearable: true, onClear: () => setDateFrom(undefined) },
            }}
            sx={{ m: 1, width: 160 }}
            value={dateFrom ? dayjs(dateFrom) : null}
            onChange={(value) => handleDateChange(value, setDateFrom)}
          />
          <DatePicker
            defaultValue={null}
            label="Date To"
            slotProps={{
              textField: { size: 'small' },
              field: { clearable: true, onClear: () => setDateTo(undefined) },
            }}
            sx={{ m: 1, width: 160 }}
            value={dateTo ? dayjs(dateTo) : null}
            onChange={(value) => handleDateChange(value, setDateTo)}
          />

          <FormControl size="small" sx={{ m: 1, minWidth: 200 }}>
            <Autocomplete
              getOptionLabel={(account) => account[1]}
              id="account-autocomplete"
              options={initialAccounts}
              renderInput={(params) => <TextField {...params} label="Account" size="small" />}
              value={accountValue}
              onChange={(e, newValue, reason) => {
                OnAutoCompleteInputChange(e, newValue, reason, setAccountValue);
              }}
            />
          </FormControl>
          <FormControl size="small" sx={{ m: 1, minWidth: 160 }}>
            <Autocomplete
              getOptionLabel={option => option}
              id="pair-autocomplete"
              options={initialPairs}
              renderInput={(params) => <TextField {...params} label="Pair" size="small" />}
              value={pairValue}
              onChange={(e, newValue, reason) => {
                OnAutoCompleteInputChange(e, newValue, reason, setPairValue);
              }}
            />
          </FormControl>
          <FormControl size="small" sx={{ m: 1, minWidth: 80 }}>
            <InputLabel id="side">Side</InputLabel>
            <Select
              id="side"
              label="Side"
              labelId="side-label"
              value={sideValue}
              onChange={(e) => setSideValue(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value='buy'>Buy</MenuItem>
              <MenuItem value='sell'>Sell</MenuItem>
            </Select>
          </FormControl>

          <FormControl size="small" sx={{ m: 1, minWidth: 200 }}>
            <Autocomplete
              getOptionLabel={(strategy) => strategy[1]}
              groupBy={(options) => options[2]}
              id="strategy-autocomplete"
              options={initialStrategies}
              renderInput={(params) => <TextField {...params} label="Strategy" size="small" />}
              value={strategyValue}
              onChange={(e, newValue, reason) =>
                OnAutoCompleteInputChange(e, newValue, reason, setStrategyValue)
              }
            />
          </FormControl>

          <FormControl size="small" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="market-type">Market Type</InputLabel>
            <Select
              multiple  // Enable multiple selection
              id="market-type"
              label="Market Type"
              labelId="market-type-label"
              renderValue={(selected) => selected.map(value => value.charAt(0).toUpperCase() + value.slice(1)).join(', ')}  // display what's been selected but have it upper case bc it looks better
              value={marketTypeValue}
              onChange={(e) => setMarketTypeValue(e.target.value)}
            >
              <MenuItem value="spot">Spot</MenuItem>
              <MenuItem value="future">Future</MenuItem>
              <MenuItem value="perp">Perpetual</MenuItem>
              <MenuItem value="option">Option</MenuItem>
            </Select>
          </FormControl>

          <Button
            color='secondary'
            sx={{ m: 1 }}
            variant="contained"
            onClick={() =>
              handleFilter({statusVal: statusHighlight, orderTypes: typeFilter, pageNumber: sharedTablePageNumber})}
          >
            Filter
          </Button>
        </Box>
      </LocalizationProvider>
    </Box>
  );
}

export { OrderSearch };
