// AuthContext.js
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { getUserMetadata } from '../../apiServices';

const UserMetadataContext = createContext();

export function UserMetadataProvider({ children }) {
  const [user, setUser] = useState({});
  const [version, setVersion] = useState('0.0.0')
  const [is_2fa_enabled, setIs2FAEnabled] = useState(false); 
  const [betaAgreedAt, setBetaAgreedAt] = useState(false);
  const [isRetail, setIsRetail] = useState(false);
  const [noAccountsFlag, setNoAccountsFlag] = useState([]);
  const [isDev, setIsDev] = useState(false);

  const loadUserMetadata = async () => {
    let metadata = null;
    try {
      metadata = await getUserMetadata();
    } catch (error) {
      return;
    }

    setUser(metadata);
    if (metadata && metadata.version) {
      setVersion(metadata.version);
    }

    setNoAccountsFlag(metadata.no_accounts_flag)
    setIsDev(metadata.is_dev);
    setIsRetail(metadata.is_retail);
    setBetaAgreedAt(metadata.beta_agreed_at);
    if (metadata.is_2fa_enabled !== undefined) {
      setIs2FAEnabled(metadata.is_2fa_enabled);
    }
  };

  useEffect(() => {
    loadUserMetadata();
  }, []);

  const value = useMemo(
    () => ({
      user, setUser, version, is_2fa_enabled, loadUserMetadata, betaAgreedAt, isRetail, noAccountsFlag, isDev,
    }),
    [user, version, is_2fa_enabled, betaAgreedAt, isRetail, setUser, loadUserMetadata, noAccountsFlag, isDev]
  );

  return (
    <UserMetadataContext.Provider value={value}>
      {children}
    </UserMetadataContext.Provider>
  );
}

export const useUserMetadata = () => useContext(UserMetadataContext);
