import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { StyledTableCell, formatDateTime } from '../orderTable/util';

const COLUMNS = [
  { id: 'created_at', label: 'Timestamp', width: 40, align: 'left' },
  { id: 'message', label: 'Message', width: 200, align: 'left' },
];

function OrderMessagesCell({ row, column }) {
  const value = row[column.id];
  switch (column.id) {
    case 'created_at':
      return <Typography>{formatDateTime(value)}</Typography>;
    default:
      return <Typography>{value}</Typography>;
  }
}

function EmptyMessages() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Typography>No messages</Typography>
    </Box>
  );
}

function LoadingMessages() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <ScaleLoader color='#FFFFFF' />
    </Box>
  );
}

function TableMessages({ orderMessages }) {
  return (
    <TableContainer style={{ height: 'calc(100% - 26px)' }}>
      <Table style={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            {COLUMNS.map((column) => (
              <StyledTableCell
                align={column.align}
                key={`main header${column.id}`}
                style={{
                  width: column.width,
                }}
              >
                <Typography>{column.label}</Typography>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {orderMessages.map((message) => (
            <TableRow key={`${message.order}-${message.id}`}>
              {COLUMNS.map((column) => (
                <StyledTableCell
                  align={column.align}
                  key={column.id}
                  style={{
                    width: column.width,
                  }}
                >
                  <OrderMessagesCell column={column} row={message} />
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function OrderMessages({ orderMessages }) {
  let content;
  let styles = {};
  if (orderMessages === null) {
    content = <LoadingMessages />;
  } else if (orderMessages.length === 0) {
    content = <EmptyMessages />;
  } else {
    content = <TableMessages orderMessages={orderMessages} />;
    styles = { overflow: 'auto' };
  }
  return (
    <Card style={{ height: '100%', p: 0, margin: 0, ...styles }}>
      <CardContent>
        <Typography variant='h4'>Messages</Typography>
        {content}
      </CardContent>
    </Card>
  );
}

export { OrderMessages };
