/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import { useTheme } from '@emotion/react';
import { AddCircleOutline } from '@mui/icons-material';
import { Box, CardHeader, IconButton, Tooltip } from '@mui/material';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import { useAtom } from 'jotai';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
  ApiError,
  fetchAccountBalances,
  fetchOrderEntryFormData,
  getAccountExchangeSettings,
  getUserFavouritePairs,
  openInNewTab,
  submitMultiOrder
} from '../../apiServices';
import { Loader } from '../../shared/Loader';
import { ErrorContext } from '../../shared/context/ErrorProvider';
import { BASEURL, removeFalsyAndEmptyKeys } from '../../util';
import MultiOrderSubmitForm from './MultiOrderSubmitForm';
import OrderFormItem from "./OrderFormItem";
import DynamicLimitSpread from './fields/DynamicLimitSpread';
import * as multiPageAtoms from './hooks/multiOrderAtoms';
import { fetchPreviewPrice } from './util';

function orderFormStateReducer(state, action) {
  switch (action.type) {
  case 'ADD_ROW':
    return {
      ...state,
      [action.payload.side]: [...state[action.payload.side], action.payload]
    };
  case 'UPDATE_ROW':
    return {
      ...state,
      [action.payload.side]: state[action.payload.side].map((item, index) =>
        index === action.payload.rowIndex ? { ...item, ...action.payload } : item
      )
    };
  case 'REMOVE_ROW': {
    const filteredAndReIndexed = state[action.payload.side]
      .filter((_, index) => index !== action.payload.rowIndex)
      .map((item, index) => ({ ...item, rowIndex: index })); // Reassign rowIndex based on new array positions
    return {
      ...state,
      [action.payload.side]: filteredAndReIndexed
    };
  }
  default:
    throw new Error(`Unknown action: ${action.type}`);
  }
}

export default function MultiOrderEntryPage() {
  const theme = useTheme();
  const [accounts, setAccounts] = useState([]);
  const [tokenPairs, setTokenPairs] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [strategyParams, setStrategyParams] = useState([]);
  const [selectedStrategy, setSelectedStrategy] = useState('');
  const [superStrategies, setSuperStrategies] = useState({});
  const [selectedStrategyParams, setSelectedStrategyParams] = useState({});
  const [selectedDuration, setSelectedDuration] = useState(900);
  const [passiveness, setPassiveness] = useState(0.02);
  const [discretion, setDiscretion] = useState(0.08);
  const [exposureTolerance, setExposureTolerance] = useState(0.2);
  const [orderCondition, setOrderCondition] = useState('');
  const [isOrderConditionValidated, setIsOrderConditionValidated] = useState(false);
  const [formState, dispatch] = useReducer(orderFormStateReducer, {'buy': [], 'sell': []});
  const [alphaTilt, setAlphaTilt] = useState(0)
  const [limitPriceSpread, setLimitPriceSpread] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)

  const [balances, setBalances] = useState({});
  const [favouritePairs, setFavouritePairs] = useState({});
  const [exchangeSettingsByAccount, setExchangeSettingsByAccount] = useState({});

  const { setHasError, setErrorContent } = useContext(ErrorContext);

  const [ dynamicLimitBPSFormat ] = useAtom(multiPageAtoms.dynamicLimitBPSFormat);

  const showAlert = ({ severity, message }) => {
    setErrorContent({ severity, message });
    setHasError(true);
  }

  const addBuyRow = () => {
    dispatch({
      type: 'ADD_ROW',
      payload: {
        accounts: [],
        pair: {base: '', id: '', label: '', quote: '', is_inverse: false, is_contract: false},
        side: 'buy',
        qty: '',
        posSide: '',
        isBaseAsset: true,
      }
    })
  }

  const addSellRow = () => {
    dispatch({
      type: 'ADD_ROW',
      payload: {
        accounts: [],
        pair: {base: '', id: '', label: '', quote: '', is_inverse: false, is_contract: false},
        side: 'sell',
        qty: '',
        posSide: '',
        isBaseAsset: true,
      }
    })
  }

  const buyOrderItems = formState.buy
  const sellOrderItems = formState.sell


  useEffect(() => {
    const getAccountBalances = async () => {
      let data;

      try {
        data = await fetchAccountBalances();
      } catch (e) {
        showAlert({ severity: 'error', message: `Unable to load account balances: ${e.message}` });
        return;
      }
      const entryBalances = {}

      data.balances.forEach(balance => {
        entryBalances[balance.account_id] = balance
      })

      setBalances(entryBalances);
    }

    const loadFavouritePairs = async () => {
      let pairs;

      try {
        const result = await getUserFavouritePairs();
        pairs = result.pairs;
      } catch (e) {
        showAlert({ severity: 'error', message: `Unable to load favourite pairs: ${e.message}` });
        return;
      }

      setFavouritePairs(pairs.reduce((acc, pair) => { return {...acc, [pair]: true} }, {}));
    }


    const loadAccountExchangeSettings = async (account_ids) => {
      try {
        const exchangeSettings = await getAccountExchangeSettings(account_ids);
        setExchangeSettingsByAccount(exchangeSettings);
      } catch (e) {
        showAlert({ severity: 'error', message: `Could not load account exchange settings: ${e.message}` });
      }
    }

    const loadInitialData = async () => {
      const data = await fetchOrderEntryFormData();
      getAccountBalances();
      loadFavouritePairs();

      const indexedStrategies = data.strategies.reduce(
        (obj, item) => {
          // VWAP doesn't work for multi order
          if (item.name !== "VWAP") {
            obj[item.id] = item;
          }
          return obj;
        },
        {}
      );
      setStrategies(indexedStrategies);

      const pairs = data.pairs.map((pair) => {
        return {
          base: pair.base,
          exchanges: pair.exchanges,
          id: pair.name,
          is_contract: pair.is_contract,
          is_inverse: pair.is_inverse,
          label: pair.name,
          market_type: pair.market_type,
          quote: pair.quote,
        }
      });

      const initialAccounts = {}
      data.accounts.forEach((acc) => {
        const scopedAccName = acc.user === data.user_id ? acc.name : `${acc.username}/${acc.name}`;
        const displayName = `${acc.exchange} - ${scopedAccName}`;
        initialAccounts[scopedAccName] =  {
          displayName,
          id: acc.id,
          name: scopedAccName,
          exchangeName: acc.exchange,
        }
      });
      const account_ids = data.accounts.map(acc => acc.id);
      if (account_ids.length > 0) {
        loadAccountExchangeSettings(account_ids);
      }

      const getVWAPTrajectory = data.strategies.find(element => element.name === 'TWAP')

      setSelectedStrategy(getVWAPTrajectory.id ? getVWAPTrajectory.id : indexedStrategies[0].id)
      setStrategyParams(data.strategy_params);
      setAccounts(initialAccounts);
      setTokenPairs(pairs);
      setIsLoading(false)
    }
    setIsLoading(true)
    loadInitialData();
    addBuyRow();
    addSellRow();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitLoading(true)

    const orderRowToFields = (row) => {
      let fields = {
        accounts: row.accounts.map(acc => acc.name),
        pair: row.pair.id,
        side: row.side,
      }

      if (row.isBaseAsset) {
        fields = {...fields, base_asset_qty: row.qty};
      } else {
        fields = {...fields, quote_asset_qty: row.qty};
      }

      if (row.posSide) {
        fields = {...fields, pos_side: row.posSide};
      }

      return fields;
    }

    let newLimitPriceSpread = limitPriceSpread;
    if ( dynamicLimitBPSFormat && limitPriceSpread){
      let sellPrice
      try{
        sellPrice = await fetchPreviewPrice(
          formState.sell[0].accounts[0].exchangeName, formState.sell[0].pair.id, showAlert);
        newLimitPriceSpread = limitPriceSpread / 10000 * sellPrice;
      } catch (e) {
        showAlert({message: `Unable to calculate BPS conversion for DynamicLimitSpread, Submit denied`,
          severity: 'error'});
        return
      }


    }

    // Js falsy includes 0, need a better way to ignore 0 as a falsy
    const orderFields =
    { ...removeFalsyAndEmptyKeys({
      alpha_tilt: alphaTilt,
      duration: selectedDuration,
      engine_passiveness: passiveness,
      schedule_discretion: discretion,
      strategy: selectedStrategy,
      strategy_params: selectedStrategyParams,
      order_condition: orderCondition,
      exposure_tolerance: exposureTolerance,
      limit_price_spread: newLimitPriceSpread,
      child_orders: [
        ...formState.buy.map((row) => orderRowToFields(row)),
        ...formState.sell.map((row) => orderRowToFields(row))]
    }),
    alpha_tilt: alphaTilt,
    engine_passiveness: passiveness,
    schedule_discretion: discretion,
    exposure_tolerance: exposureTolerance,
    }

    try {
      const response = await submitMultiOrder(orderFields);
      if (response.id) {
        setSubmitLoading(false)
        openInNewTab(`${BASEURL}/multi_order/${response.id}`)
      } else {
        showAlert(response, 'error');
      }
    } catch (e) {
      if (e instanceof ApiError) {
        showAlert(e.message, 'error');
      } else {
        throw e;
      }
    }
  };

  const multiOrderFormProps = {
    strategies, strategyParams, selectedStrategy, setSelectedStrategy,
    selectedDuration, selectedStrategyParams, setSelectedStrategyParams,
    setSelectedDuration, passiveness, setPassiveness, discretion, setDiscretion,
    exposureTolerance, setExposureTolerance, orderCondition, setOrderCondition,
    isOrderConditionValidated, setIsOrderConditionValidated, superStrategies,
    handleSubmit, showAlert, formState, alphaTilt, setAlphaTilt, submitLoading
  }

  if (isLoading) {
    return <Box height='100%'>
      <Card>
        <CardContent>
          <Loader />
        </CardContent>
      </Card>
    </Box>
  }

  return (
    <Stack direction='column' height='100%' spacing={1}>
      <Box height='325px'>
        <Card style={{overflow: 'auto', width: '100%'}}>
          <CardContent style={{paddingBottom: '16px', height: 'calc(100% - 32px)'}}>
            <Stack direction='column' height='100%' width='100%'>
              <MultiOrderSubmitForm {...multiOrderFormProps} />
            </Stack>
          </CardContent>
        </Card>
      </Box>
      <Box display='flex' flexDirection='row' height='calc(100% - 466px)' width='100%'>
        <Stack direction='row' spacing={1} width='100%'>
          <Card style={{width: '100%'}}>
            <CardHeader title='Buy' />
            <CardContent style={{paddingBottom: '16px', height: 'calc(100% - 88px)', overflow: 'auto'}}>
              <Stack direction="column" spacing={0} width='100%'>
                {
                  buyOrderItems.map((formStateRow, rowIndex) =>
                    <OrderFormItem
                      accounts={accounts}
                      balances={balances}
                      dispatch={dispatch}
                      exchangeSettingsByAccount={exchangeSettingsByAccount}
                      favouritePairs={favouritePairs}
                      index={rowIndex}
                      key={`buy${rowIndex}`}
                      orderItemState={formStateRow}
                      setFavouritePairs={setFavouritePairs}
                      showAlert={showAlert}
                      tokenPairs={tokenPairs}
                    />
                  )
                }
                <Box display='flex' sx={{ marginTop: '5px', justifyContent: 'center' }}>
                  <Tooltip title="Add new buy order item">
                    <IconButton onClick={addBuyRow}>
                      <AddCircleOutline sx={{ fontSize: 25, color: theme.palette.grey.main}} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Stack>
            </CardContent>
          </Card>
          <Card style={{width: '100%'}}>
            <CardHeader title='Sell' />
            <CardContent style={{paddingBottom: '16px', height: 'calc(100% - 88px)', overflow: 'auto'}}>
              <Stack direction='column' spacing={0} width='100%'>
                {
                  sellOrderItems.map((formStateRow, rowIndex) =>
                    <OrderFormItem
                      accounts={accounts}
                      balances={balances}
                      dispatch={dispatch}
                      exchangeSettingsByAccount={exchangeSettingsByAccount}
                      favouritePairs={favouritePairs}
                      index={rowIndex}
                      key={`buy${rowIndex}`}
                      orderItemState={formStateRow}
                      setFavouritePairs={setFavouritePairs}
                      showAlert={showAlert}
                      tokenPairs={tokenPairs}
                    />
                  )
                }
                <Box display='flex' sx={{ marginTop: '5px', justifyContent: 'center' }}>
                  <Tooltip title="Add new sell order item">
                    <IconButton onClick={addSellRow}>
                      <AddCircleOutline sx={{ fontSize: 25, color: theme.palette.grey.main}} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Stack>

            </CardContent>
          </Card>
        </Stack>
      </Box>
      <Box height='150px'>
        <Card>
          <CardContent>
            <DynamicLimitSpread
              formState={formState}
              limitPriceSpread={limitPriceSpread}
              setLimitPriceSpread={setLimitPriceSpread}
              showAlert={showAlert}
            />
          </CardContent>
        </Card>
      </Box>
    </Stack>
  )
}