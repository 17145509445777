import { Box } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import Highcharts from 'highcharts/highstock';
// eslint-disable-next-line import/no-unresolved
import { useAtomsDebugValue } from 'jotai-devtools/utils';
import React, { useEffect, useState, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import AccountBalancePage from './pages/accountBalance/AccountBalancePage';
import PointsPage from './pages/points/PointsPage';
import AccountSettings from './pages/accountSettings/AccountSettings';
import OrderEntryPage from './pages/dashboard/OrderEntryPage';
import KeyManagementPage from './pages/keyManagement/KeyManagementPage';
import MultiOrderEntryPage from './pages/multiOrder/MultiOrderEntryPage';
import OrderDetailsPage from './pages/orderDetails/algoOrderDetails/OrderDetailsPage';
import MultiOrderDetailsPage from './pages/orderDetails/multiOrderDetails/MultiOrderDetailsPage';
import ChainedOrderEntryPage from './pages/chainedOrders/ChainedOrdersEntryPage';
import SimpleOrderDetailsPage from './pages/orderDetails/simpleOrderDetails/SimpleOrderDetailsPage';
import OrderViewPage from './pages/orderView/OrderViewPage';
import TransactionCostsPage from './pages/transactionCosts/TransactionCostsPage';
import { ErrorProvider } from './shared/context/ErrorProvider';
import { theme } from './theme/theme';
import VersionUpdateModal from './shared/VersionUpdateModal';
import { getVersionData } from './apiServices';

import '../css/index.css';
import AgreementGatedRoute from './routing/AgreementGatedRoute';
import NavBar from './routing/navBar';
import AdminPanel from './pages/admin/AdminPanel';
import TermsAndConditions from './pages/agreements/TermsAndConditions';
import OptionOrderPage from './pages/optionOrderEntry/OptionOrderPage';
import {
  UserMetadataProvider,
  useUserMetadata,
} from './shared/context/UserMetadataProvider';
import ErrorBoundary from './routing/ErrorBoundary';
import PrivateRoute from './routing/PrivateRoute';
import { Loader } from './shared/Loader';
import ChainedOrderDetailsPage from './pages/orderDetails/chainedOrderDetails/ChainedOrderDetailsPage';
import ExplorerPage from './pages/explorer/ExplorerPage';
import ExplorerTradesPage from './pages/explorer/ExplorerTradesPage';
import ExplorerProofsPage from './pages/explorer/ExplorerProofsPage';
import DicyPage from './pages/dicy/DicyPage';
import ReferralPage from './pages/referral/ReferralPage';

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: 'IBM PLEX MONO',
    },
  },
});

const releaseNotesUrl =
  'https://foul-wavelength-9af.notion.site/TaaS-Release-Notes-2a2ca754c9e24e7e9ee71fc2d00f64ad';

function ComponentTree({ children = null }) {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <ErrorProvider>{children}</ErrorProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

function App() {
  const { user, version, isRetail, isDev, isMetadataLoading } =
    useUserMetadata();
  const isAuthenticated = user && user.is_authenticated;
  const storedVersionRef = useRef(localStorage.getItem('appVersion'));
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [versionDetails, setVersionDetails] = useState('');
  const [versionContent, setVersionContent] = useState('');

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    async function checkVersion() {
      const storedVersion = localStorage.getItem('appVersion');
      const isVersionChecked = sessionStorage.getItem('isVersionChecked');
      let response;
      try {
        response = await getVersionData();
      } catch (error) {
        return;
      }

      if (isVersionChecked === 'true' && version === storedVersion) {
        return;
      }

      // If version from backend has changed
      if (version && version !== '0.0.0' && version !== storedVersion) {
        const newVersionContent = response.VERSION_FEATURES;
        setVersionContent(newVersionContent);
        setVersionDetails(`Version ${version}`);
        setShowUpdateModal(true);
        storedVersionRef.current = version;
        localStorage.setItem('appVersion', version);
        sessionStorage.setItem('isVersionChecked', 'true');
      }
    }

    checkVersion();
  }, [version, user]);

  return (
    <ComponentTree>
      <Router>
        <Box height='100vh' minWidth='1440px' width='100%'>
          {!isMetadataLoading ? (
            <>
              <NavBar version={version} />
              <Box
                marginTop='8px'
                paddingX='8px'
                style={{
                  height: 'calc(100% - 78px)',
                  width: 'calc(100% - 16px)',
                }}
                sx={{ overflow: 'hidden' }}
              >
                <ErrorBoundary>
                  <Routes>
                    {isAuthenticated ? (
                      <Route element={<AgreementGatedRoute />}>
                        <Route element={<OrderEntryPage />} path='/' />
                      </Route>
                    ) : (
                      <Route element={<OrderEntryPage />} path='/' />
                    )}

                    <Route element={<OrderDetailsPage />} path='/order/:uuid' />

                    <Route
                      element={<MultiOrderDetailsPage />}
                      path='/multi_order/:uuid'
                    />
                    <Route
                      element={<ChainedOrderDetailsPage />}
                      path='/chained_orders/:uuid'
                    />
                    <Route
                      element={<SimpleOrderDetailsPage />}
                      path='/simple_order/:uuid'
                    />

                    {!isRetail && (
                      <Route element={<AdminPanel />} path='/admin_panel' />
                    )}

                    <Route
                      element={<TermsAndConditions />}
                      path='/beta_agreement'
                    />
                    <Route element={<Navigate to='/' />} path='*' />
                    <Route element={<ReferralPage />} path='/referral/:code' />
                    <Route
                      element={
                        <PrivateRoute isAuthenticated={isAuthenticated} />
                      }
                    >
                      <Route element={<AgreementGatedRoute />}>
                        <Route
                          element={<OptionOrderPage />}
                          path='/enter_option_order'
                        />
                        <Route
                          element={<KeyManagementPage />}
                          path='/key_management'
                        />
                        <Route
                          element={<MultiOrderEntryPage />}
                          path='/enter_multi_order'
                        />
                        <Route
                          element={<ChainedOrderEntryPage />}
                          path='/enter_chained_order'
                        />
                        {isDev && (
                          <Route element={<ExplorerPage />} path='/explorer' />
                        )}
                        <Route
                          element={<ExplorerProofsPage />}
                          path='/explorer/proofs'
                        />
                        <Route
                          element={<ExplorerTradesPage />}
                          path='/explorer/trades'
                        />
                        <Route element={<DicyPage />} path='/dicy' />
                      </Route>
                      <Route element={<AccountSettings />} path='/settings' />
                      <Route element={<OrderViewPage />} path='/view_orders' />
                      <Route
                        element={<AccountBalancePage />}
                        path='/account_balances'
                      />
                      <Route element={<PointsPage />} path='/points' />
                      <Route element={<ReferralPage />} path='/referral' />
                      <Route
                        element={<TransactionCostsPage />}
                        path='/transaction_costs'
                      />
                    </Route>
                  </Routes>
                </ErrorBoundary>
              </Box>
            </>
          ) : (
            <Loader />
          )}
        </Box>
      </Router>
      <VersionUpdateModal
        open={showUpdateModal}
        versionContent={versionContent}
        versionDetails={versionDetails}
        onClose={() => setShowUpdateModal(false)}
        onReadMore={() => {
          window.open(releaseNotesUrl, '_blank');
          setShowUpdateModal(false);
        }}
      />
    </ComponentTree>
  );
}

function DebugAtoms() {
  useAtomsDebugValue();
  return null;
}

const dashboardRoot = createRoot(document.getElementById('root'));
dashboardRoot.render(
  <UserMetadataProvider>
    <DebugAtoms />
    <App />
  </UserMetadataProvider>
);
