import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import DurationDropdown from '../../shared/fields/DurationDropdown';
import {
  AlphaTiltSlider,
  DiscretionSlider,
  ExposureToleranceSlider,
  PassivenessSlider,
} from "../../shared/fields/Sliders";
import {
  ConditionValidateField,
  StrategyParamSelect
} from '../../shared/fields/StrategyParamSelect';
import TrajectoryDropdown from '../../shared/fields/TrajectoryDropdown';
import * as FormAtoms from '../dashboard/orderEntry/hooks/useFormReducer';
import DurationField from '../dashboard/orderEntry/AlgoOrderFieldsComponents/DurationField';


export default function MultiOrderSubmitForm({
  strategies, strategyParams,
  selectedStrategy, setSelectedStrategy,
  selectedStrategyParams, setSelectedStrategyParams,
  selectedDuration, setSelectedDuration,
  passiveness, setPassiveness,
  discretion, setDiscretion,
  exposureTolerance, setExposureTolerance,
  orderCondition, setOrderCondition,
  isOrderConditionValidated, setIsOrderConditionValidated,
  handleSubmit,
  showAlert, formState,
  alphaTilt, setAlphaTilt, submitLoading
}) {
  const theme = useTheme();

  const buyOrderItems = formState.buy
  const sellOrderItems = formState.sell
  const areOrderItemsValid = Object.values(formState).every(side => {
    return side.every((item) => item.accounts.length > 0 && item.pair && item.qty);
  })

  const enoughItems = buyOrderItems.length > 0 && sellOrderItems.length > 0;

  const orderConditionValid = !orderCondition || isOrderConditionValidated;
  const isReadyToSubmit = areOrderItemsValid && enoughItems && orderConditionValid;

  // const [preTradeEstimationData, setPretradeEstimationData] = useState({})

  // const fetchTradePrediction = async () => {
  //   let selectedAccounts = []
  //   let qty = 0;
  //   let selectedPair = {}

  //   (formState.buy).forEach((row) => {
  //     row.forEach(e => {
  //       selectedAccounts.push(...e.accounts.map(acc => acc.name));
  //       qty += e.qty; // obv wrong, fix later
  //       selectedPair = e.pair.id; // same here
  //     })
  //   });

  //   (formState.sell).forEach((row) => {
  //     row.forEach(e => {
  //       selectedAccounts.push(...e.accounts.map(acc => acc.name));
  //       qty += e.qty; // obv wrong, fix later
  //       selectedPair = e.pair.id; // same here
  //     })
  //   });

  //   selectedAccounts = [...new Set(selectedAccounts)];

  //   disabled before we figure out what to do about multi order pre-trade
  //   try {
  //     if(isReadyToSubmit) {
  //       const data = await getPreTradePrediction(selectedAccounts, selectedPair, qty, selectedDuration);
  //       setPretradeEstimationData({
  //         pov: data.pov,
  //         volatility: data.volatility,
  //         marketVolume: data.market_volume,
  //       })
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       showAlert(`${error.response.status} - ${error.response.data}`, 'error');
  //     } else if (error.request) {
  //       showAlert('No response from server.', 'error');
  //     } else {
  //       showAlert(error.message, 'error');
  //     }
  //   }
  // };

  // useEffect(() => {
  //   fetchTradePrediction();
  // }, [selectedDuration, formState]);

  const handleStrategyParamChange = (event) => {
    setSelectedStrategyParams({
      ...selectedStrategyParams,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <form height='100%' onSubmit={handleSubmit}>
      <Stack direction='row' height='100%' spacing={5}>
        <Box style={{width: '34%'}}>
          <Grid container spacing={1}>
            <Grid xs={12}>
              <TrajectoryDropdown
                setTrajectory={setSelectedStrategy}
                trajectories={strategies}
                trajectory={selectedStrategy}
              />
            </Grid>
            <Grid xs={12}>
              <DurationField
                disabled={false}
                FormAtoms={FormAtoms}
                isCalculatingDuration={false}
                selectedDuration={selectedDuration}
                setSelectedDuration={setSelectedDuration}
              />
            </Grid>
            <Grid xs={12}>
              <ConditionValidateField
                isOrderConditionValidated={isOrderConditionValidated}
                orderCondition={orderCondition}
                rows={2}
                setIsOrderConditionValidated={setIsOrderConditionValidated}
                setOrderCondition={setOrderCondition}
                showAlert={showAlert}
              />
            </Grid>
            <Grid xs={12}>
              { !submitLoading ?
                <Button
                  fullWidth
                  disabled={!isReadyToSubmit}
                  sx={{height: '40px'}}
                  type="submit"
                  variant="contained"
                >
                  <Typography color={!isReadyToSubmit ? 'grey' : 'text.offBlack'} variant='h6'>
                    Submit Multi Order
                  </Typography>
                </Button> :
                <Button
                  disabled
                  fullWidth
                  sx={{height: '40px'}}
                  type="submit"
                  variant="contained"
                >
                  <CircularProgress size={20} />
                </Button> }
            </Grid>
          </Grid>
        </Box>
        <Box style={{width: '33%', height: '100%'}}>
          <Stack spacing={0}>
            <ExposureToleranceSlider
              exposureTolerance={exposureTolerance} setExposureTolerance={setExposureTolerance}
              sx={{marginBottom: '10px'}}
            />
            <PassivenessSlider passiveness={passiveness} setPassiveness={setPassiveness} sx={{marginBottom: '10px'}}
            />
            <DiscretionSlider discretion={discretion} setDiscretion={setDiscretion} sx={{marginBottom: '10px'}}
            />
            <AlphaTiltSlider alphaTilt={alphaTilt} setAlphaTilt={setAlphaTilt} sx={{marginBottom: '10px'}}
            />
          </Stack>
        </Box>
        <Box sx={{width: '33%', height: '100%'}}>
          <StrategyParamSelect
            handleStrategyParamChange={handleStrategyParamChange}
            selectedStrategyParams={selectedStrategyParams}
            strategyParams={strategyParams}
          />
        </Box>
      </Stack>
    </form>
  )
}
