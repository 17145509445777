import { Button, Card, CardContent, CardHeader, Icon, Link, Table, TableBody,
  TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React,{ useState, useEffect } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { displayDefaultTableCell, StyledHeaderTableCellWithLine, StyledTableCell } from "../../shared/orderTable/util";
import basescanLight from '../../../images/logos/basescan-light.png'

const columns = [
  { id: 'transactionHash', label: 'Transaction ID', width: 260,  align: 'left' },
  { id: 'type', label: 'Type', width: 50,  align: 'left' },
  { id: 'attester', label: 'Attester', width: 50,  align: 'left' },
  { id: 'blockNumber', label: 'Block', width: 60,  align: 'left' },
  { id: 'merkleRoot', label: 'Hash', width: 80, align: 'left' },
]

const abi = [{
  "type": "event",
  "name": "AttestedToData",
  "inputs": [
    {
      "name": "traderId",
      "type": "bytes32",
      "indexed": true,
      "internalType": "bytes32"
    },
    {
      "name": "epoch",
      "type": "uint256",
      "indexed": true,
      "internalType": "uint256"
    },
    {
      "name": "attester",
      "type": "address",
      "indexed": false,
      "internalType": "address"
    },
    {
      "name": "record",
      "type": "tuple",
      "indexed": false,
      "internalType": "struct Attestations_Types.DataRecord",
      "components": [
        {
          "name": "merkleRoot",
          "type": "bytes32",
          "internalType": "bytes32"
        },
        {
          "name": "cid",
          "type": "string",
          "internalType": "string"
        }
      ]
    }
  ]
}];

function insertEllipsis(str, startSlice = 4, endSlice = 3) {
  if (!str) {
    return '';
  }

  if (str.length <= 7) {
    return str; // No need to shorten
  }

  const start = str.slice(0, startSlice);
  const end = str.slice((-1 * endSlice));

  return `${start}...${end}`;
}

async function fetchAttestedToDataEvents(config) {
  const {
    rpcUrl,
    contractAddress,
    numberOfBlocks,
    retry,
    paginationNumber,
  } = config;

  try {
    const provider = new ethers.JsonRpcProvider(rpcUrl);
    const currentBlock = await provider.getBlockNumber();
    const fromBlock = (currentBlock - (paginationNumber * numberOfBlocks)) - numberOfBlocks;

    const contract = new ethers.Contract(contractAddress, abi, provider);
    const events = [];


    const batchSize = 5000;
    // const endBlock = Math.min(fromBlock + batchSize, currentBlock) - (paginationNumber * numberOfBlocks);

    for (let batch = fromBlock; batch < currentBlock - (paginationNumber * numberOfBlocks); batch += batchSize) {
      const endBlock = Math.min(batch + batchSize, currentBlock)
      let retryCount = 0;
      while (retryCount < retry) {
        try {

          // eslint-disable-next-line no-await-in-loop
          const batchEvents = await contract.queryFilter(
            'AttestedToData',
            batch,
            endBlock
          )

          batchEvents.forEach((event) => {
            events.push({
              transactionHash: event.transactionHash,
              blockNumber: event.blockNumber,
              traderId: event.args.traderId,
              epoch: event.args.epoch,
              attester: event.args.attester,
              merkleRoot: event.args.record.merkleRoot,
              cid: event.args.record.cid,
              type: 'Trade'
            });
          })

          break;

        } catch (error) {
          retryCount += 1;
          console.error(`Attempt ${retryCount} failed for batch ${batch}-${endBlock}:`, error.message);

          if (retryCount === retry) {
            console.error(`Failed to fetch batch ${batch}-${endBlock} after ${retry} attempts: ${error.message}`);
          }

          Promise(resolve => setTimeout(resolve, 2000));
        }
      }
    }


    return events;

  } catch (error) {
    console.error('Error fetching AttestedToData events:', error);
  }
  return []
}


export default function ExplorerProofsTable({
  setHasError,
  setErrorContent,
  pageView
}) {
  const [proofs, setProofs] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [paginationTracker, setPaginationTracker] = useState(0)

  const navigate = useNavigate()
  const theme = useTheme()

  const rowsPerPage = pageView ? 10 : 25;

  const getBaseScan = async (paginationNumber = 0) => {
    setLoading(true)
    try {
      const lastNBlocks = 50000
      const config = {
        contractAddress: '0xC31c7663873d36bC63bA28df4D40D0102F73D1B5',
        numberOfBlocks: lastNBlocks,
        rpcUrl: 'https://mainnet.base.org',
        retry: 3,
        paginationNumber
      };

      const events = await fetchAttestedToDataEvents(config, setHasError,
        setErrorContent);
      setLoading(false)
      return events
    } catch (error) {
      console.error('Error in main:', error);
    }
    setLoading(false)
    return []
  }

  const handleChangePage = (event, newPage) => {
    if(newPage * rowsPerPage >= proofs.length){
      getBaseScan(paginationTracker + 1).then(result => {
        setProofs((prev) => [...result, ...prev])
      })
      setPaginationTracker(prev => prev + 1)
    }

    setPage(newPage);
  };


  useEffect(() => {

    getBaseScan().then(result => {
      setProofs((prev) => [...result, ...prev])
    })

    const transactioninterval = setInterval(() => {

      getBaseScan(paginationTracker).then(result => {
        setProofs(result)
        setPage(0)
      })
    } , 600000);

    return () => {
      clearInterval(transactioninterval)
    }
  }, []);

  return (
    <Card sx={{ height: '95%' }}>
      <Stack alignItems="center" direction="row" spacing={2} style={{paddingLeft: '20px', paddingTop: '20px'}}>
        <img alt="Base Scan" src={basescanLight} style={{ height: '30px'}} />
        <Typography variant='h4'>Oracle</Typography>
      </Stack>
      <CardContent>
        <Box sx={{ height: '100%'}}>
          {loading ? (
            <Box
              alignItems="center"
              display="flex"
              height= 'calc(100% - 95px)'
              justifyContent="center"
            >
              <ScaleLoader color={theme.palette.text.offWhite} />
            </Box>) : (
            <TableContainer sx={{maxHeight: 'calc(100% - 95px)'}}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledHeaderTableCellWithLine
                        align={column.align}
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          width : column.width || undefined,
                        }}
                      >
                        {column.label}
                      </StyledHeaderTableCellWithLine>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody sx={{overflow: 'auto'}}>
                  {proofs.sort((a,b) => b.blockNumber - a.blockNumber)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((tx) => {
                      return (
                        <TableRow
                          hover
                          key={tx.transactionHash}
                          sx={{
                            height: '55.38px'
                          }}
                        >
                          {columns.map((column) => {
                            const value = tx[column.id];
                            switch(column.id){
                            case 'merkleRoot':
                              return displayDefaultTableCell(column, insertEllipsis(value,8,6), {}, StyledTableCell)
                            case 'attester':
                              return displayDefaultTableCell(column, insertEllipsis(value), {}, StyledTableCell)
                            case 'transactionHash':
                              return  (<StyledTableCell align={column.align} key={column.id}>
                                <Link href={`https://basescan.org/tx/${value}`} >
                                  {insertEllipsis(value,8,6)}
                                </Link>
                              </StyledTableCell>)
                            default:
                              return displayDefaultTableCell(column, value, {}, StyledTableCell)
                            }
                          })}
                        </TableRow>
                      )})}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {pageView ?
            <Button
              sx={{width: '100%', height: '50px', border: 0,
                color: theme.palette.text.offWhite, borderColor: theme.palette.text.offWhite}}
              variant="outlined"
              onClick={() => navigate('/explorer/proofs')}
            >
              View all proofs
            </Button>
            :<TablePagination
              component="div"
              count={-1}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10]}
              sx={{height: '60px'}}
              onPageChange={handleChangePage}
            />
          }
        </Box>
      </CardContent>
    </Card>
  )

}
